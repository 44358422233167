import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Modal,
  Card,
  Stack
} from "react-bootstrap";
import ComponentCard from "./new/ComponentCard";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { auth } from "../firebase";
import { getUserFromEmail } from "../functions/getUser";
import { useCookies } from "react-cookie";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import check from "../assets/icons/svg/check.png";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ShowServiceClass() {

  const serviceName = "";
  const serviceID = ""
  const [serviceUpdatesID, setServiceUpdatesID] = useState("");
  const [FPP, setFPP] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [userCanceledService, setUserCanceledService] = useState(false);
  const [waitSucess, setWaitSucess] = useState(false);
  const [cookies, setCookie] = useCookies(["name"]);
  useAuth();
  const navigate = useNavigate();

  const [showAlreadyTriedModal, setShowAlreadyTriedModal] = useState(false);
  const [show, setShow] = useState(false);

  const closeAlreadyTriedModal = () => {
    setShowAlreadyTriedModal(false);
    navigate("/dashboard");
  }
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const columns = [];
    (async () => {
      try {
        const userEmail = auth.currentUser.email;
        const userData = await getUserFromEmail(userEmail);
        const current_Id = userData.service_updates
          ? userData.service_updates
          : userData.service;
        setServiceUpdatesID(current_Id);
        setFPP(userData.FPP);
        // console.log("userData.FPP", userData.FPP);
        if (userData.FPP !== 0) {
          setShowAlreadyTriedModal(true);
        }
        const maintenanceData = await getUserFromEmail(
          "maintenance@cynorix.com"
        );
        const products = maintenanceData.products;


        // column data field will be rendered in the order of the list
        const propList = [
          "subscription_name",
          "basic_rate",
          "currency",
          "billing_period",
        ];
        propList.forEach((item, index) => {
          columns.push({
            dataField: item,
            text: item
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" "),
          });
        });

        // attributesDict stores all unique attributes
        const attributesDict = {};
        for (const product in products) {
          const attributes = products[product].attributes;
          for (const attribute in attributes) {
            attributesDict[attribute] = attributes[attribute]["name"];
          }
        }
        // add attributes to columns
        for (const attribute in attributesDict) {
          columns.push({
            dataField: attribute,
            text: attributesDict[attribute],
          });
        }

        const listOfProductsInfo = [];
        for (const product in products) {
          const productInfo = products[product];
          // add each attribute in attributesDict to newProduct
          for (const attribute in attributesDict) {
            if (productInfo.attributes[attribute]) {
              const attr = productInfo.attributes[attribute];
              // if productInfo has attribute, load data
              productInfo[attribute] =
                "limit: " +
                attr.limit +
                ", chunk: " +
                attr.chunk +
                ", rate past limit: " +
                attr.rate;
            } else {
              // else set to N/A by default
              productInfo[attribute] =
                "limit: N/A, chunk: N/A, rate past limit: N/A";
            }
          }

          delete productInfo.attributes;

          listOfProductsInfo.push(productInfo);
        }
        setUserCanceledService(await getCancelServiceStatus(userEmail));
      } catch (e) {
        //console.log(e);
        alert("Failed to show Subscription Tiers");
      }
    })();
  }, [navigate, serviceUpdatesID]);

  const getCancelServiceStatus = async (email) => {
    return await postWithCredentials(SERVER_URL + "checkCancelServiceStatus", {
      userEmail: email,
    })
      .then((response) => {
        return response.data.canceled;
      })
      .catch((err) => {
        // console.log(err);
        //throw new Error();
      });
  };

  const setUserService = async (Id) => {
    const uid = auth.currentUser.uid;
    const userEmail = auth.currentUser.email;
    let subscribed = false;
    let subId = "";
    await getUserFromEmail(userEmail).then((userData) => {
      // determine if user is in FPP 0 or FPP 1
      // Double checking if user FPP is 1 or not. This would handel the payment
      if (userData.subscription_id && userData.FPP === 1) {
        subscribed = true;
        subId = userData.subscription_id;
      }
      // if (userData.subscription_id) {
      // 	subscribed = true;
      // 	subId = userData.subscription_id;
      // }
    });

    if (subscribed) {
      await updateSubscription(subId, Id, uid, userEmail);
      setTimeout(() => {
        navigate(`/dashboard`);
      }, 1000);
    } else {
      //   setTimeout(() => {
      //   	history.push(`/create-subscription/?class_id=${Id}`);
      //   }, 1000);

      /// CP45
      setTimeout(async () => {
        //await createSubscriptionWp(subId, Id, uid, userEmail); // Create new Sub without the payment
        const id =
          "00e6ec406788f5846b2d8b38e2b4c23dc4271b71c16d548b827186d82f6d3bb4";
        await createSubscriptionWp(id, uid, userEmail);
        navigate(`/dashboard`); // Takes to service component which has to be changed
      }, 1000);
    }
  };

  /// Create New Sub without payment
  const createSubscriptionWp = async (classId, userId, userEmail) => {
    await postWithCredentials(SERVER_URL + "createSubscriptionWp", {
      classId,
      userId,
      userEmail,
      cancellingSubs: false,
    })
      .then((response) => {
        // console.log("Subscription Successfully Updated");
      })
      .catch((err) => {
        // console.log(err);
        setError("Failed to Switch Service, Please try after some time!");
        //throw new Error('Failed to create/update subscription')
      });
  };

  const updateSubscription = async (subId, classId, userId, userEmail) => {
    await postWithCredentials(SERVER_URL + "updateSubscription", {
      subId,
      classId,
      userId,
      userEmail,
      cancellingSubs: false,
    })
      .then((response) => {
        // console.log("Subscription Successfully Updated");
        setSuccess("Successfully Switched Service");
        // These line wait till 2 mins to allow next subscription
        var date = new Date();
        date.setTime(date.getTime() + 60 * 2000);
        setCookie("name", "newName", { expires: date, path: "/" });
        setWaitSucess(true);
      })
      .catch((err) => {
        // console.log(err);
        setError("Failed to Switch Service, Please try after some time!");
        //throw new Error('Failed to create/update subscription')
      });
    // const index = generateEmailIndex(currentUser.email);
    // await sendChangeSubTier(currentUser.email, index);
  };

  const helpContent = (
    <>
      Suppose the user wants to try this encryption service. They can start by
      clicking <b>Try Free Trial</b>. The user will be asked to confirm that
      they want to try the free trial. Afterwards, they will be redirected to
      their <b>Service Usage Information</b> page. The user will have access to
      this free trial until the next month.
    </>
  );

  return (
    <ComponentCard title="Try Free Trial" helpContent={helpContent}>
      <Card
        className="d-flex justify-content-center align-items-center mt-5"
        style={{
          width: "35rem",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "20px",
          border: "1px solid black",
        }}
      >
        <Card.Body className="pt-4 pb-0">
          <Card.Title className="text-center mt-1">
            <b>Free Trial</b>
          </Card.Title>
        </Card.Body>
        <hr
          style={{
            border: "0",
            clear: "both",
            display: "block",
            width: "50%",
            backgroundColor: "#000000",
            height: "1px",
          }}
        />
        <Card.Body className="mb-3">
          {/* Plan Options */}
          <Stack className="align-items-start mb-4" gap={3}>
            <span>
              <img className="icon mx-1" src={check} alt="check" />
              Number of Encryptions: <b>15</b>
            </span>
            <span>
              <img className="icon mx-1" src={check} alt="check"></img>
              Rate for next <b>{5}</b> encryptions: <b>N/A</b>
            </span>
            <span>
              <img className="icon mx-1" src={check} alt="2FA"></img>
              2FA
            </span>
          </Stack>
          {/* Selection Button */}
          <Button style={{ width: "100%" }} type="submit" onClick={handleShow}>
            Select Subscription Tier
          </Button>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* I have added cookies too see what would happen if there already exist */}
          {cookies.name !== "newName" && FPP === 0 && (
            <div>
              Click confirm to select the free trial.
              <strong>{" " + serviceName}</strong>
            </div>
          )}
          {cookies.name !== "newName" && FPP === 1 && (
            <div>
              If you want to switch your subscription tier, please click the
              confirm button to continue. After confirmation, your current
              subscription tier will be switched to
              <strong>{" " + serviceName}</strong> at the beginning of next
              month.
            </div>
          )}
          {cookies.name !== "newName" && userCanceledService && (
            <div>
              <strong>Notice:</strong> Since your subscription for next month
              was canceled previously, if you confirm to select this class of
              service, your subscription will be resumed.
            </div>
          )}
          {cookies.name === "newName" && waitSucess !== true && (
            <div>
              You have recently changed your subscription status. Please try
              after one hour.
            </div>
          )}
          {cookies.name !== "newName" && FPP === 4 && (
            <div>
              <strong>Notice:</strong> You are no longer eligible for a free
              trial.
            </div>
          )}
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>

          {/* Ternary operetator to see if a user has changed subscription recently  */}

          {cookies.name === "newName" ? (
            <Button variant="primary" onClick={handleClose}>
              Go back
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setUserService(serviceID);
                handleClose();
              }}
            >
              {FPP === 0 ? "Confirm" : "Cancel"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showAlreadyTriedModal}>
        <Modal.Header>
          <Modal.Title>Free Trial Unavailable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Sorry, you have used our free trial already and are not eligible to use our free trial again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeAlreadyTriedModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </ComponentCard>
  );
}
