import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBa5wZxRNvSn7IOWs_NjSjAUhD9XjTSdK4",
  authDomain: "cynorix-msfs-v2.firebaseapp.com",
  databaseURL: "https://cynorix-msfs-v2-default-rtdb.firebaseio.com",
  projectId: "cynorix-msfs-v2",
  storageBucket: "cynorix-msfs-v2.appspot.com",
  messagingSenderId: "86615903552",
  appId: "1:86615903552:web:a41678fffa27cccf34bea5",
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
