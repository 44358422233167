import { useEffect, useState } from "react";
import { Alert, Button, Card, CloseButton, Col, Collapse, Form, ListGroup, Modal, Stack } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import ComponentCard from "./new/ComponentCard";
import FeedbackAlert from "./new/FeedbackAlert";
import FileUploadBox from "./new/FileUploadBox";
import FeedbackProgressBar from "./new/FeedbackProgressBar";
import LoadingButton from "./LoadingButton";
import HelpButton from "./HelpButton";
import CryptoJS from 'crypto-js';
import { PhoneNumberUtil } from "google-libphonenumber";
import axios from "axios";
import notifications from "../assets/json/notifications.json";
import JSZip from "jszip";
import { oldDownloadFile, oldEncrypt } from "../services/encryption";
import {oldEncrypt1,oldDownloadFile1 } from "../services/encryptionUtilsNew";
import { sendEncryptionData } from "../services/server-connector";
import { ENCRYPTION_SUCCESS } from "../assets/js/constants";

const phoneUtil = PhoneNumberUtil.getInstance();
const MAX_FILE_SIZE = 1.75 * 1024 * 1024 * 1024; // 1.75GB in bytes

export function bytesToString(bytes) {
  const denominations = ["KB", "MB", "GB", "TB"];
  // 1 KB = 2^10 bytes.
  // 1 MB = 2^20 bytes.
  // 1 GB = 2^30 bytes, and so on.
  const bitPlaces = Math.floor(Math.log2(bytes));
  let index = Math.floor(bitPlaces / 10) - 1;
  if (index < 0) {
    index = 0;
  } else if (index > 3) {
    index = 3;
  }
  const largestUnitInBytes = Math.pow(2, (index + 1) * 10);

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
  const formattedNumber = formatter.format(bytes / largestUnitInBytes);
  return `${formattedNumber} ${denominations[index]}`;
}

const today = new Date();
const defaultExpiryDate = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  today.getDate()
);
const minExpiryDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 1
);
const minEncryptExpiry = minExpiryDate.toISOString().slice(0, 16);
const maxEncryptExpiry = new Date(
  today.getFullYear() + 1,
  today.getMonth(),
  today.getDate()
)
  .toISOString()
  .slice(0, 16);
const defaultEncryptionOptions = {
  expiryDateTime: defaultExpiryDate.toISOString().slice(0, 16),
  decryptionLimit: 1,
  fileName: `Custom File Name (Expires ${defaultExpiryDate.toString().slice(0, 21)})`,
  myEmail: "",
  receiver2FA: "None",
};

function InlineFormInput({ label, helpContent, children, className, ...rest }) {
  const controlId = `form-${label.split("s+").join("")}`;
  return (
    <Form.Group
      controlId={controlId}
      className={`row gx-3 ${className} d-flex justify-content-between`}
      {...rest}
    >
      <Col xs={16} sm={"auto"} className="pe-0">
        <Form.Label column style={{pointerEvents: "none"}}>
          {label}
        </Form.Label>
        {helpContent ? <HelpButton header={label}>{helpContent}</HelpButton>
          : null}
      </Col>
      <Col lg={10}>{children}</Col>
    </Form.Group>
  );
}

function InlineFormSwitch({ label, helpContent, value, onChange, ...rest }) {
  return (
    <Alert
      className={`d-flex py-1 ps-3 ${value ? "" : "bg-light"}`}
      variant={value ? "primary" : "secondary"}
      style={{
        border: "none",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      {...rest}
    >
      <div>
        <Form.Label column xs={"auto"} style={{ userSelect: "none"}} >
          {label}
        </Form.Label>
        {helpContent ? <HelpButton header={label}>{helpContent}</HelpButton>
            : null}
      </div>
      <Form.Label className="row mx-1 py-3 my-1" column></Form.Label>
      <Form.Check type="switch" checked={value} onChange={onChange}/>
    </Alert>
  );
}

export default function Encrypt(props) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  // invariant: user is signed in
  const userEmail = currentUser.email;

  // states
  const [files, setFiles] = useState([]);
  const [encryptionOptions, setEncryptionOptions] = useState({
    ...defaultEncryptionOptions,
    myEmail: currentUser && currentUser.email,
  });
  const [isCustomExpiry, setIsCustomExpiry] = useState(false);
  const [isNotifyUserOnDecrypt, setIsNotifyUserOnDecrypt] = useState(false);

  // medical specific states:
  const [recipient, setRecipient] = useState("");
  const [isSendDecryptMessage, setIsSendDecryptMessage] = useState(false);
  const [decryptMessage, setDecryptMessage] = useState("");

  const [feedback, setFeedback] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [serviceUseable, setServiceUseable] = useState(false);
  const [serviceNotification, setServiceNotification] = useState(null);
  const [userOutOfUses, setUserOutOfUses] = useState(false);

  const handleEncryptionOptionsChange = (e) => {
    e.preventDefault();
    setEncryptionOptions({
      ...encryptionOptions,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // reset custom expiry if disabled
    if (!isCustomExpiry) {
      setEncryptionOptions((opt) => ({
        ...opt,
        expiryDateTime: defaultExpiryDate.toISOString().slice(0, 16),
      }));
    }
  }, [isCustomExpiry]);

  useEffect(() => {
    // reset custom email notification if disabled
    if (!isNotifyUserOnDecrypt) {
      setEncryptionOptions((opt) => ({
        ...opt,
        myEmail: currentUser && currentUser.email,
      }));
    }
  }, [isNotifyUserOnDecrypt, currentUser]);

  useEffect(() => {
    // reset custom message on decrypt if disabled
    if (!isSendDecryptMessage) {
      setDecryptMessage("");
    }
  }, [isSendDecryptMessage]);

  useEffect(() => {
    // check service useable
    if (currentUser) {
      checkService(currentUser.email);
    }
  }, [currentUser]);

  useEffect(() => {
    setEncryptionOptions((opt) => {
      if (opt.fileName === "" || (opt.fileName.includes("(Expires") && opt.fileName.includes(")"))) {
        const beforeIndex = opt.fileName.indexOf("(Expires");
        const beginning = opt.fileName.substring(0, beforeIndex);
        const afterIndex = opt.fileName.indexOf(")", beforeIndex);
        const ending = opt.fileName.substring(afterIndex + 1, opt.fileName.length);
        return {
          ...opt,
          fileName:
            beginning + "(Expires " +
            new Date(opt.expiryDateTime).toString().slice(0, 21) +
            ")" + ending,
        };
      } else {
        return opt;
      }
    });
  }, [encryptionOptions.expiryDateTime]);

  async function checkService(email) {
    setFeedback({
      variant: "info",
      message: "Getting Subscription Information",
      loading: true,
    });
    // check service useable
    try {
      const res = await postWithCredentials(
        `${process.env.REACT_APP_SERVER_URL}checkServiceUsable`,
        {
          userEmail: email,
          service: "files_encrypted",
        }
      );
      setServiceUseable(res.data.usable);
      if (!res.data.usable) {
        // not useable, notify and disabled
        if (res.data.fpp === 2) {
          setServiceNotification(notifications.userData.employeeOutOfUses);
        } else if (res.data.fpp === 1) {
          setServiceNotification(notifications.userData.outOfUses);
          setUserOutOfUses(true);
        } else {
          setServiceNotification(notifications.userData.subscriptionNotFound);
        }
      }
      setFeedback(null);

      return res.data.usable;
    } catch (e) {
      console.log("err while checking service", e);
      return false;
    }
  }

  // input validation of "soft" rules. Hard rules (like date input, email
  // input, and non-empty checks) are enforced by browser.
  function validateInputs() {
    const validation = [];

    // validate file length
    if (files.length <= 0) {
      validation.push("Please upload a file to encrypt.");
    }
    //validate if file length if greater than 2GB
    // if (files.length >= 0) {
    //   validation.push("Please upload a file to encrypt.");
    // }
    console.log("file",files.length)

    // validate date
    if (
      Date(encryptionOptions["expiryDateTime"]) < Date(minEncryptExpiry) &&
      Date(encryptionOptions["expiryDateTime"]) > Date(maxEncryptExpiry)
    ) {
      validation.push(`Date must be after ${minEncryptExpiry}`);
    }

    // validate sms fields
    try {
      const phoneNum = phoneUtil.parseAndKeepRawInput("+" + recipient);
      if (!phoneUtil.isValidNumber(phoneNum)) {
        validation.push(`Recipient must have a valid phone number`);
      }
    } catch (e) {
      validation.push(`Recipient must have a valid phone number`);
    }

    if (validation.length > 0) {
      setFeedback({
        variant: "warning",
        message: validation.map((str, i) => <span key={i}>{str}</span>),
      });
      return false;
    }

    return true;
  }

  async function encryptFile() {
    console.log("Compression Begin")
    // invariant: data already validated && user has service
    setFeedback({
      loading: true,
      message: "Compressing file...",
      variant: "info",
    });
    setDownloadProgress({
      loading: true,
      now: 0,
      variant: "info",
    });

    // create zip file of files
    let zip = new JSZip();
    // add all the files to the zip
    files.forEach((file) => {
      zip.file(file.name, file);
    });

    try {
      const blob = await zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        streamFiles: true,
      }, function updateCallback(metadata) {
        if (Math.random() > 0.01) return; //Slows callbacks down
        setDownloadProgress({
          loading: true,
          now: Math.round(metadata.percent),
        });
    });
      const zipFile = new File([blob], "zipped.zip", {
        type: "blob",
        compression: "DEFLATE",
      });

      console.log("Compression successful");

      setFeedback({
        loading: true,
        message: "Encrypting file...",
        variant: "info",
      });
      setDownloadProgress({
        loading: true,
        now: 0,
      });

      // call encrypt/oldEncrypt
      const [x2Hashed, xOrKey, initHashed, iv, otp, encryptedFileData] =
        await oldEncrypt1(zipFile, setDownloadProgress);

      const sendResult = await sendEncryptionData({
        init: x2Hashed,
        key: xOrKey,
        proof: initHashed,
        iv: iv,
        otp: otp,
        uses: encryptionOptions["decryptionLimit"],
        time: new Date(encryptionOptions["expiryDateTime"]),
        email: encryptionOptions["myEmail"],
        recipientContact: recipient,
        filename: encryptionOptions["fileName"],
        message: decryptMessage,
        smsSentTime: 0,
        isNotifEnabled: isNotifyUserOnDecrypt,
      });

      if (sendResult.message === ENCRYPTION_SUCCESS) {
        //save file to computer
        // await oldDownloadFile(
        //   encryptedFileData.ciphertext,
        //   `${encryptionOptions.fileName}.cy`
        // );
        // Save file to computer
        await oldDownloadFile1(encryptedFileData, `${encryptionOptions.fileName}.cy`);
        
        setFeedback({
          variant: "success",
          message: "File Successfully Encrypted and placed in your Downloads folder!",
        });
        setDownloadProgress({
          loading: false,
        });
      } else {
        setFeedback({
          variant: "danger",
          message: "A problem occurred while encrypting your file.",
        });
      }
      return true;
    } catch (e) {
      console.log("Compression or encryption not sucessful");
      setFeedback({
        variant: "danger",
        message: "A problem occurred while encrypting your file.",
      });
      return false;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    setFeedback({
      loading: true,
      variant: "info",
      message: "Submitting...",
    });

    // validation check
    if (!validateInputs()) return;

    try {
      // service check
      const isValidService = await checkService(currentUser.email);
      if (!isValidService) return;

      // encrypt file
      const fileSuccessfullyEncrypted = await encryptFile();
      if (!fileSuccessfullyEncrypted) return;
    } catch (e) {
      console.log(e);
    }

    // update service count
    const payload1 = {
      email: currentUser.email,
      service: "files_encrypted",
      id: currentUser.id,
      count: 1,
    };
    postWithCredentials(
        process.env.REACT_APP_PAYMENT_SYSTEM_SERVER_URL + "updateServiceCount",
        payload1
      )
      .then(() => {})
      .catch((e) => {
        console.error("Update service count failed", e);
      })
      .finally(() => {
        // clear all options
        setEncryptionOptions({
          ...defaultEncryptionOptions,
          myEmail: currentUser.email,
        });
        setIsCustomExpiry(false);
        setIsNotifyUserOnDecrypt(false);
        setIsSendDecryptMessage(false);
        setDecryptMessage("");
        setRecipient("");
        setFiles([]);
      });
  }

  const helpContent = (
    <p className="mb-1">
      Start by clicking on <b>Click to add or drop files here</b>. There are a
      few parameters that you can change before encrypting the file:
      <ul>
        <li>
          <b>Expiry Date/Time</b>: You can customize when the encrypted file
          will expire. Afterwards, it cannot be decrypted. By default, files
          expire a month after being encrypted.
        </li>
        <li>
          <b>File Name</b>: A name for the encrypted file.
        </li>
        <li>
          <b>Notify Me When the Recipient Decrypts the File</b>: You can choose to
          receive an email whenever this file is decrypted.
        </li>
        <li>
          <b>Send a Custom SMS to Recipient</b>: Allows the sender to compose a
          message tailored to the particular case/client/document that will be
          included in the SMS sent to the recipient cell phone.
        </li>
        <li>
          <b>Recipient Phone Number</b>: Recipient will be authenticated by
          receiving an SMS. Fill in the recipient’s cell phone number. Make sure
          that the recipient can receive SMS.
        </li>
      </ul>
      Once ready, press <b>Submit</b> to encrypt the file.The resultant file will be placed in your "Downloads" folder (on your local computer) for you to share by your method of choice.
    </p>
  );

  return (
    <ComponentCard title="Encrypt Files" helpContent={helpContent}>
      {/* FILE UPLOAD CARD */}
      <Card
        style={{
          boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {/* <FileUploadBox
          handleFileUpload={(uploads) => {
            // uploads is a FileList, iterate and add to files
            const uploadArray = [];
            for (const file of uploads) {
              uploadArray.push(file);
            }
            setFiles(files.concat(uploadArray));
          }}
          style={{
            minHeight: 120,
            height: files.length > 0 ? 120 : "100%",
            display: "flex",
            borderRadius: 10,
            margin: 10,
            border: "2px dashed gray",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card.Text>Click to add or drop files here...</Card.Text>
        </FileUploadBox> */}

        <FileUploadBox
          handleFileUpload={(uploads) => {
            const uploadArray = Array.from(uploads); // Convert FileList to Array
            const currentTotalSize = files.reduce((total, file) => total + file.size, 0);
            const uploadTotalSize = uploadArray.reduce((total, file) => total + file.size, 0);
            
            if (currentTotalSize + uploadTotalSize > MAX_FILE_SIZE) {
              // If total size exceeds 2GB, show an alert and don't add files
              setFeedback({
                variant: "danger",
                message: "Cannot upload. The total size of files exceeds 1.75GB.",
              });
              return; // Stop the function here
            }

            // If total size is within the limit, proceed to add files
            setFiles([...files, ...uploadArray]);
            // Clear any existing feedback related to file size
            setFeedback(null);
          }}
          style={{
            minHeight: 120,
            height: files.length > 0 ? 120 : "100%",
            display: "flex",
            borderRadius: 10,
            margin: 10,
            border: feedback && feedback.variant === "danger" ? "2px dashed red" : "2px dashed gray",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card.Text>Click to add or drop files here...</Card.Text>
        </FileUploadBox>

        {/* List of uploaded files*/}
        <Card.Body
          className="pt-2"
          style={{
            display: files.length > 0 ? null : "none",
          }}
        >
          <span
            className="ms-3 my-1"
            style={{
              color: "#424242",
            }}
          >
            <b>Files Uploaded</b>
          </span>
          <ListGroup
            style={{
              maxHeight: "20vh",
              overflow: "scroll",
              width: "100%",
            }}
          >
            {files.map((file, index) => {
              return (
                <ListGroup.Item key={index}>
                  {`${file.name} - ${bytesToString(file.size)}`}
                  <CloseButton
                    style={{ float: "right" }}
                    aria-label="Remove Upload"
                    onClick={(e) => {
                      setFiles(files.filter((_, i) => i !== index));
                    }}
                  />
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Card.Body>
      </Card>
      {/* ENCRYPTION OPTION FORM */}
      <Form className="mt-3 px-2" onSubmit={onSubmit}>
        {/* FILE NAME */}
        <InlineFormInput 
          label={"File Name"} 
          helpContent={
            "You can choose a custom name for your file or you can use the " + 
            "default. The default file name contains the expiry date as a " + 
            "reminder and will update if you change the expiry date."} 
          className="mb-2" >
          <Form.Control
            name="fileName"
            value={encryptionOptions["fileName"]}
            onChange={handleEncryptionOptionsChange}
            required
          />
        </InlineFormInput>
        <hr className="my-3" />
        {/* CUSTOM FILE EXPIRY DATE */}
        <Form.Group controlId="CustomExpirySwitch" className="mb-1">
          <InlineFormSwitch
            label="Set a Custom Expiry Date/Time"
            helpContent={
              "You can customize when the encrypted file will expire. Afterwards, " + 
              "it cannot be decrypted. By default, files expire a month after being " +
              "encrypted. The default file name contains the expiry date as a " +
              "reminder and will update if you change the expiry date."
            }
            value={isCustomExpiry}
            onChange={(e) => {
              setIsCustomExpiry(e.target.checked);
            }}
          />
        </Form.Group>
        <Collapse in={isCustomExpiry}>
          <div className="px-2">
            <InlineFormInput label={"Expiry Date/Time"} className="pb-3">
              <Form.Control
                type="datetime-local"
                disabled={!isCustomExpiry}
                name="expiryDateTime"
                min={minEncryptExpiry}
                max={maxEncryptExpiry}
                value={encryptionOptions["expiryDateTime"]}
                onChange={handleEncryptionOptionsChange}
                required
              />
            </InlineFormInput>
          </div>
        </Collapse>
        {/* NOTIFY ME UPON DECRYPTION */}
        <Form.Group controlId="NotifyMe" className="mb-1">
          <InlineFormSwitch
            label="Notify Me When the Recipient Decrypts the File"
            helpContent={
              "You can choose to receive an email when this file is decrypted. " +
              "By default, the email will be sent to your email, but you can " +
              "change it below."}
            value={isNotifyUserOnDecrypt}
            onChange={(e) => {
              setEncryptionOptions({
                ...encryptionOptions,
                myEmail: userEmail,
              });
              setIsNotifyUserOnDecrypt(e.target.checked);
            }}
          />
        </Form.Group>
        <Collapse in={isNotifyUserOnDecrypt}>
          <div>
            <InlineFormInput label={"My Email"} className="pb-3">
              <Form.Control
                type="email"
                disabled={!isNotifyUserOnDecrypt}
                name="myEmail"
                value={encryptionOptions["myEmail"]}
                onChange={handleEncryptionOptionsChange}
                required
              />
            </InlineFormInput>
          </div>
        </Collapse>
        <hr className="mb-3 mt-0" />
        {/* RECIPIENT'S PHONE NUMBER */}
        <InlineFormInput 
          label={"Recipient Phone"} 
          helpContent={
            "Please enter the phone number of the recipient.  " +
            "When they attempt to decrypt this file, we will verify " +
            "their identity by sending an SMS to their phone number."
          }
          className="pb-3">
          <PhoneInput
            className="phone"
            country="ca"
            style={{ width: "100%" }}
            inputProps={{ style: { width: "100%" }, required: true }}
            value={recipient}
            onChange={(number) => {
              setRecipient(number);
            }}
          />
        </InlineFormInput>
        {/* CUSTOM DECRYPTION MESSAGE */}
        <Form.Group controlId="CustomDecryptionMessageSwitch">
          <InlineFormSwitch
            label="Send a Custom SMS to the Recipient"
            helpContent={
              "Send a custom message to the recipient. It will be sent via SMS " + 
              "when the recipient is attempting to decrypt the file."
            }
            value={isSendDecryptMessage}
            onChange={(e) => {
              setIsSendDecryptMessage(e.target.checked);
            }}
          />
        </Form.Group>
        <Collapse in={isSendDecryptMessage}>
          <div>
            <InlineFormInput label="Message" className="pb-3">
              <Form.Control
                as="textarea"
                disabled={!isSendDecryptMessage}
                value={decryptMessage}
                onChange={(e) => {
                  setDecryptMessage(e.target.value);
                }}
              />
            </InlineFormInput>
          </div>
        </Collapse>
        <hr className="my-3" />
        {/* WARNING ABOUT SINGLE DECRYPTION */}
        <p>
          <strong>Remember, your file can only be decrypted once.</strong>
        </p>
        {/* ENCRYPT BUTTON */}
        <LoadingButton
          type="submit"
          className="w-100"
          loading={feedback && feedback.loading}
          disabled={files.length <= 0 || !serviceUseable}
        >
          Submit
        </LoadingButton>
      </Form>
      <FeedbackAlert feedback={feedback} className="mt-2 mb-2" />
      <FeedbackProgressBar downloadProgress={downloadProgress} className="mt-2 mb-2" />

      <Modal show={serviceNotification} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>
            <b>{serviceNotification && serviceNotification.title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {serviceNotification && serviceNotification.message}
          <Stack direction="horizontal" gap={2} className="mt-3">
            <Button
              className="w-100"
              id="modal-dashboard-button"
              variant="secondary"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Dashboard
            </Button>
            {userOutOfUses &&
              <Button
                className="w-100"
                id="modal-chunks-button"
                onClick={() => {
                  navigate("/chunk");
                }}
              >
                Buy Chunks
              </Button>
            }
            <Button
              className="w-100"
              id="modal-upgrade-button"
              onClick={() => {
                navigate("/upgrade-service");
              }}
            >
              Upgrade Service
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </ComponentCard>
  );
}
