import React, { createRef, useRef, useState } from "react";
import { Form, Button, Alert, Row } from "react-bootstrap";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import ComponentCard from "./new/ComponentCard";
import "./Signup.css";
import ReactPasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
library.add(faEye, faEyeSlash);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const passwordConfirmRef = useRef();
  const reRef = createRef();
  const verificationCodeRef = useRef();
  const { signup } = useAuth();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [verifying, setVerifying] = useState(false);
  const [handling, setHandling] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);
  const [codeSent, setCodeSent] = useState(false);
  const [codeError, setCodeError] = useState("");
  let [remainingSubmit, setRemainingSubmit] = useState(3);
  let [code, setCode] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordCShown, setPasswordCShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const navigate = useNavigate();

  //After the user clicked the send code button, they have 3 opportunities to click the submit button.

  const handleSendVerificationCode = async (e) => {
    e.preventDefault();
    try {
      //avoid the server to handle two request at the same time
      if (handling) {
        return;
      } else {
        setHandling(true);
      }
      //Check password
      var regExp = /[a-zA-Z]/g;
      var hasNumber = /\d/;
      if (
        passwordRef.current.value.length < 10 ||
        !regExp.test(passwordRef.current.value) ||
        !hasNumber.test(passwordRef.current.value)
      ) {
        setHandling(false);
        throw new Error("Password too weak");
      }
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        setHandling(false);
        throw new Error("Passwords do not match");
      }
      if (emailRef.current.value.length < 1 || !emailRef.current.value.includes("@")) {
        setHandling(false);
        throw new Error("Please enter an email");
      }
      if (firstNameRef.current.value.length < 1) {
        setHandling(false);
        throw new Error("Please enter a first name");
      }
      if (lastNameRef.current.value.length < 1) {
        setHandling(false);
        throw new Error("Please enter a last name");
      }
      if (passwordRef.current.value.length < 1) {
        setHandling(false);
        throw new Error("Please enter a password");
      }
      if (passwordConfirmRef.current.value.length < 1) {
        setHandling(false);
        throw new Error("Please confirm your password");
      }

      //Verify reCATPCHA
      const token = await reRef.current.getValue();
      await verifyCaptcha(token);

      //Set remainingSubmit equal to 3
      setRemainingSubmit(3);

      // generate 6-digit code
      let randomCode = (Math.floor(Math.random() * 1000000) + 1000000)
        .toString()
        .substring(1);
      setCode(randomCode);

      await sendVerificationCodeEmail(randomCode);

      // start countdown after email is sent
      setCodeSent(true);
      setVerifying(true);
      setLoading(false);
      setTimeout(() => {
        setVerifying(false);
        setTimeLeft(-1);
        setLoading(true);
        setCode("");
        setRemainingSubmit(3);
      }, 120000);
      // show countdown after email is sent
      let counter = timeLeft - 1;
      const interval = setInterval(() => {
        setTimeLeft(counter);
        counter--;
        if (counter < 0) {
          clearInterval(interval);
          setTimeLeft(120);
        }
      }, 1000);
    } catch (e) {
      // console.log(e);
      setError(e.message);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
    setHandling(false);
  };
  const handleEnterEvent = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("in submit");
    if (handling) {
      return;
    } else {
      setHandling(true);
    }

    try {
      setError("");
      //Check Code
      let inputCode = verificationCodeRef.current.value;

      if (remainingSubmit > 0 && inputCode === "") {
        setCodeError("Please Enter Code.");
        setHandling(false);
        return;
      }
      if (remainingSubmit > 0) setRemainingSubmit(remainingSubmit - 1);
      if (remainingSubmit === 0) {
        setLoading(true);
        setHandling(false);
        return;
      }
      if (inputCode === code) {
      } else {
        setCodeError(
          "Your Code is incorrect. You have " +
            (remainingSubmit - 1) +
            " more attempts to verify"
        );
        setTimeout(() => {
          setCodeError("");
        }, 3000);
        setHandling(false);
        return;
      }

      // handle errors
      const errorCode = await signup(
        emailRef.current.value,
        passwordRef.current.value,
        firstNameRef.current.value,
        lastNameRef.current.value
      );
      if (errorCode) {
        if (errorCode === "auth/invalid-email") {
          setLoading(false);
          setHandling(false);
          throw new Error("The email is invalid.");
        }
        if (errorCode === "auth/email-already-in-use") {
          setLoading(false);
          setHandling(false);
          throw new Error("The email is already in use.");
        }
        if (errorCode === "auth/weak-password") {
          setLoading(false);
          setHandling(false);
          throw new Error("The password is too weak.");
        }
      }
      // Create a customer on Stripe
      await createCustomer();
      // no error
      setLoading(false);
      setHandling(false);
      navigate("/set-google-auth");
      window.location.reload(true);
    } catch (e) {
      setError(e.message);
      setTimeout(() => {
        setError("");
        setVerifying(false);
        setLoading(true);
        setCode("");
      }, 2000);
    }
    setLoading(false);
    setHandling(false);
  };

  const createCustomer = async () => {
    await postWithCredentials(SERVER_URL + "createStripeCustomer", {
      name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
      email: emailRef.current.value,
    })
      .then((response) => {})
      .catch((err) => {
        // console.log(err);
        throw new Error("Failed Creating a Customer on Stripe");
      });
  };

  const verifyCaptcha = async (token) => {
    return await Axios.post(SERVER_URL + "verifyCaptcha", {
      captcha: token,
    })
      .then((response) => {
        // console.log('Captcha Passed');
      })
      .catch((err) => {
        if (err.response.data.msg) {
          throw new Error(err.response.data.msg);
        } else {
          throw new Error("Failed captcha verification");
        }
      });
  };

  const sendVerificationCodeEmail = async (code) => {
    await Axios.post(SERVER_URL + "sendVerificationCodeEmail", {
      receiver: emailRef.current.value,
      code: code,
    })
      .then((response) => {
      })
      .catch((err) => {
        // console.log(err);
        throw new Error("Failed Sending Verification Email");
      });
  };

  const helpContent = (
    <p className="my-1">
      On the Sign Up page, you will be asked to provide your email address, name
      and password. You will also need to complete a CAPTCHA before submitting
      your information. Once this information is submitted, a confirmation code
      will be sent to your email address. The interface will prompt you to enter
      this confirmation code within a time window, which upon completion, your
      account is created.
    </p>
  );

  return (
    <ComponentCard title="Register" helpContent={helpContent}>
      <Form
        onSubmit={handleSendVerificationCode}
        className="password-container"
      >
        <Form.Group controlId="email" className="form-group">
          <Form.Label className="form-group-label">Email</Form.Label>
          <Form.Control
            className="form-group-input"
            type="email"
            placeholder="example@mail.com"
            ref={emailRef}
            //required
            disabled={verifying}
          />
        </Form.Group>

        <Form.Group controlId="firstName" className="form-group">
          <span className="form-group-label">First Name</span>
          <Form.Control
            className="form-group-input"
            type="text"
            placeholder="Jane"
            ref={firstNameRef}
            //required
            disabled={verifying}
          />
        </Form.Group>
        <Form.Group controlId="lastName" className="form-group">
          <span className="form-group-label">Last Name</span>
          <Form.Control
            className="form-group-input"
            type="text"
            placeholder="Doe"
            ref={lastNameRef}
            //required
            disabled={verifying}
          />
        </Form.Group>
        <Form.Group
          controlId="password"
          className="form-group"
          style={{ position: "relative" }}
        >
          <Form.Label className="form-group-label">Password</Form.Label>
          <Form.Control
            type={passwordShown ? "text" : "password"}
            placeholder="********"
            className="form-group-input"
            ref={passwordRef}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            //required
            disabled={verifying}
          />
          {/* Put the click on the bounding div for easier click */}
          <div className="iconButton" onClick={togglePassword}>
            <FontAwesomeIcon icon={passwordShown ? "eye-slash" : "eye"} />
          </div>
        </Form.Group>
        <div
          className="form-group"
          style={{
            textAlign: "left",
            justifyContent: "start",
          }}
        >
          <span className="form-group-label"></span>

          <ReactPasswordChecklist
            rules={["minLength", "letter", "number"]}
            minLength={10}
            value={password}
            onChange={(isValid) => {
              // console.log(passwordRef);
            }}
            className="form-group-input"
          />
        </div>
        <Form.Group
          controlId="password-confirm"
          className="form-group"
          style={{ position: "relative" }}
        >
          <span className="form-group-label">Confirm Password</span>
          <Form.Control
            className="form-group-input"
            type={passwordCShown ? "text" : "password"}
            placeholder="********"
            ref={passwordConfirmRef}
            //required
            disabled={verifying}
          />
          {/* Put the click on the bounding div for easier click */}
          <div
            className="iconButton"
            onClick={() => {
              setPasswordCShown(!passwordCShown);
            }}
          >
            <FontAwesomeIcon icon={passwordCShown ? "eye-slash" : "eye"} />
          </div>
        </Form.Group>
        <div>
          <ReCAPTCHA
            className="mt-3 mb-3"
            style={{ justifyContent: "center", display: "flex" }}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={reRef}
          />
        </div>

        <Row>
          <div className="form-group">
            <span className="form-group-label" style={{ flex: 1 }}>
              Email Verification Code
            </span>
            <Form.Group controlId="verification-code" style={{ flex: 3.4 }}>
              {/* <Form.Label>Email Verification Code</Form.Label> */}
              <Form.Control
                className="form-group-input"
                type="text"
                ref={verificationCodeRef}
                onKeyDown={handleEnterEvent}
              />
            </Form.Group>
            <Button
              disabled={verifying}
              type="submit"
              id="code-send-button"
              className="btn btn-primary h-100"
              style={{ flex: 0.6, backgroundColor: "#7F0000" }}
            >
              {codeSent ? "Resend" : "Send"}
            </Button>
          </div>
        </Row>
        {verifying && remainingSubmit > 0 && (
          <Alert variant="success" className="mt-3">
            A 6-digit verification code is sent to the email{" "}
            {emailRef.current.value}, the code will expire in {timeLeft}{" "}
            seconds.
          </Alert>
        )}
        {!verifying && codeSent && (
          <Alert variant="warning" className="mt-3">
            Code is expired, please try another time.
          </Alert>
        )}
        {/* {success && <Alert variant="success">{success}</Alert>} */}
        {remainingSubmit === 0 && (
          <Alert variant="warning" className="mt-3">
            You failed to enter email verification code 3 times, please retry in{" "}
            {timeLeft} seconds.
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
        {codeError && (
          <Alert variant="danger" className="mt-3">
            {codeError}
          </Alert>
        )}
        <Button
          disabled={loading || remainingSubmit === 0}
          onClick={handleSubmit}
          class="w-100 mt-3"
        >
          Sign Up
        </Button>
        <div>
          <p className="mb-0 mt-3">
            Already have an account? <a href="/login">Login</a>
          </p>
        </div>
      </Form>
    </ComponentCard>
  );
}
