import React, {useState, useEffect, useCallback} from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  Card,
  Alert,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { auth } from "../firebase";
import { verifyCode } from "../functions/verify";
import ComponentCard from "./new/ComponentCard";
import "./OAuth.css";
import LoadingButton from "./LoadingButton";
import axios from "axios";
import { postWithCredentials } from "../contexts/AuthContext"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function OAuth() {
  const [data, setData] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [confirmIntention, setConfirmIntention] = useState(false);
  const [gettingQRCode, setGettingQRCode] = useState(false);
  const [haveSecret, setHaveSecret] = useState(false);
  const navigate = useNavigate();

  const renderQRCode = useCallback(async () => {
    setGettingQRCode(true);
    try {
      const userId = auth.currentUser.uid;
      const qrCode = await generateQRCode(userId);
      setData(qrCode);
    } catch (e) {
      setError("Error Rendering QR Code. Please try refreshing.");
      // console.log(e);
    }
    setGettingQRCode(false);
  }, [setData, setError, setGettingQRCode]);

  useEffect(() => {
    if (confirmIntention) {
      renderQRCode();
    }
  }, [confirmIntention, renderQRCode]);

  useEffect(() => {
    // console.log(auth.currentUser.uid, "this is the uid");
    postWithCredentials(SERVER_URL + "have2fa", {
        user_id: auth.currentUser.uid,
      })
      .then((res) => {
        // console.log(res.data.haveSecret);
        setHaveSecret(res.data.haveSecret);
      });
  }, []);



  const generateQRCode = async (id) => {
    return await postWithCredentials(SERVER_URL + "qrcode", {
      userId: id,
    })
      .then((response) => {
        // console.log('Posted QR code');
        if (response.data.data === {}) {
          throw Error("No QR Code");
        }
        return response.data.data;
      })
      .catch((err) => {
        // console.log(err);
        throw err;
      });
  };

  const verify2FA = async () => {
    try {
      const userId = auth.currentUser.uid;
      const verification = await verifyCode(code, userId);
      if (verification) {
        setSuccess("Successfully set up 2-Factor Authentication.");
        setError("");
        setTimeout(() => {
          if (auth.currentUser.email === "maintenance@cynorix.com") {
            navigate("/maintenance-dashboard");
          } else {
            navigate("/dashboard");
          }
        }, 1000);
      } else {
        setError("The code is incorrect");
      }
    } catch (err) {
      // console.log(err);
      setError("Error verifying the code, please try again");
      window.location.reload();
    }
  };

  const content = (
    <>
      <h3 className="subheading">Download Google Authenticator</h3>
      <p className="text">
        Download and open the Google Authenticator app on your mobile phone
      </p>
      <h3 className="subheading">Scan QR Code</h3>
      <div style={{ textAlign: "left" }}>
        <p className="text">
          Please scan the QR code shown below using the Google Authenticator 
          app to register this application. Then, enter the six digit code from 
          the app in order to complete the setup.
        </p>
      </div>

      <div className="d-flex justify-content-center position-relative">
        <Card.Img
          className="mb-3 w-25 mt-3 p-0"
          src={data}
          alt=""
          style={{ boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)" }}
        />
        {gettingQRCode ? (
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" variant="primary" size="lg" />
          </div>
        ) : null}
      </div>
      {error && (
        <Alert variant="danger" className="d-flex align-items-center p-2">
          <span className="me-auto">{error}</span>
          <LoadingButton
            onClick={(e) => {
              renderQRCode();
            }}
            loading={gettingQRCode}
            className="me-0 mx-auto"
          >
            Refresh QR Code
          </LoadingButton>
        </Alert>
      )}
      {success && <Alert variant="success">{success}</Alert>}
      <div className="form-group">
        <span className="form-group-label">Verification Code</span>
        <Form.Control
          className="mb-3 form-group-input"
          type="text"
          onChange={(event) => setCode(event.target.value)}
          placeholder="Enter Code from Google Authenticator"
        ></Form.Control>
      </div>
      <LoadingButton
        style={{
          marginRight: "10px",
          width: "100%",
        }}
        loading={gettingQRCode}
        className="w-100 mb-1"
        onClick={verify2FA}
      >
        Verify Code
      </LoadingButton>
    </>
  );

  function generateContent() {
    if (confirmIntention) {
      return content;
    } else {
      return (
        <>
          {haveSecret ? (
            <span>
              You have 2-Factor-Authentication already, do you want to reset it?
              Click 'Yes' to continue or 'No' to go back.
              <br></br>
              <strong>
                Please note that once you confirm your decision by clicking
                'Yes', your current 2FA will be terminated. To continue using
                2FA related services, you will need to complete the process of
                registering for a new 2FA.
              </strong>{" "}
            </span>
          ) : (
            <span>
              If you want to set 2-Factor-Authentication, please click Yes
              button to continue.
            </span>
          )}

          <Row>
            <Col>
              <Button
                className="w-100 mt-3"
                onClick={() => {
                  setConfirmIntention(true);
                }}
              >
                Yes
              </Button>
            </Col>
            <Col>
              <Button
                className="w-100 mt-3"
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                No
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  }

  const helpContent = (
    <>
      <p className="mb-1">
        <b>Warning</b>: Once you begin the 2FA process, your old 2FA is erased.
        If you decide to exit this process midway, you will invalidate
        your previous 2FA and must re-register the 2FA QR Code again.
      </p>
      <p>
        To minimize accidental clicks, this page requires you to confirm your
        intention before it begins the process of registering you for 2FA. To
        exit this page, you can click Go Back in the sidebar. To continue, click
        Yes on the page.
      </p>
      <hr />
      <p className="mb-1">
        The next page details a list of instructions for the user to register
        2FA. Once registered, you will then see a six-digit code in the
        authenticator app on your smartphone. Enter that six-digit code into the
        web application and click <b>Verify Code</b> to finish 2FA registration.{" "}
      </p>
      <p className="mb-1">
        <em>
          Note: This code is only valid for a short time, so it is
          important to enter the code before it expires. If the code you entered
          is incorrect, you can attempt the code again, or you can click the
          Refresh QR Code button,{" "}
          <b>
            after which you will need to restart the registration procedure
            again.
          </b>
        </em>
      </p>
    </>
  );

  return (
    <ComponentCard
      title="Set Up 2-Factor Authentication Using Google Authenticator"
      helpContent={helpContent}
    >
      {generateContent()}
    </ComponentCard>
  );
}
