import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Alert, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import Axios from "axios";
import { handleLoginError } from "./Login";
import { verifyCode } from "../functions/verify";
import { getUserFromEmail } from "../functions/getUser";
import { useCookies } from "react-cookie";
import ComponentCard from "./new/ComponentCard";
import FeedbackAlert from "./new/FeedbackAlert";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SelectSponsor() {
  const passwordRef = useRef("");
  const codeRef = useRef("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["cancelService"]);
  const { generateEmailIndex, currentUser } = useAuth();
  const [feedback, setFeedback] = useState(null);
  const [haveSecret, setHaveSecret] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const auth = getAuth();

  const navigate = useNavigate();

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const sendCancelServiceEmail = async (userEmail, userIndex) => {
    return await postWithCredentials(SERVER_URL + "sendCancelServiceEmail", {
      userEmail,
      userIndex,
    })
      .then(() => {
        // console.log('Confirmation Email Sent');
      })
      .catch((err) => {
        // console.log(err);
        throw new Error("Error sending confirmation email, please try again.");
      });
  };

  useEffect(() => {
    postWithCredentials(SERVER_URL + "have2fa", {
      user_id: auth.currentUser.uid,
    }).then((res) => {
      setHaveSecret(res.data.haveSecret);
    });
  }, [auth.currentUser.uid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      setFeedback(null);
      setLoading(true);

      // Verify Password
      const auth = getAuth();
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        passwordRef.current.value
      );
      try {
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (e) {
        throw new Error(e.code);
      }
      if (haveSecret) {
        // Verify 2FA Code
        const verified = await verifyCode(
          codeRef.current.value,
          auth.currentUser.uid
        );
        if (!verified) {

          setFeedback({
            message: "Incorrect Code.",
            variant: "danger",
          });
          setLoading(false);
          return;
        }
      }

      let subId;
      const userData = await getUserFromEmail(auth.currentUser.email);
      if (userData.subscription_id) {
        subId = userData.subscription_id;
      }

      await CancelSubscription(subId, userData.user_id);
      const index = generateEmailIndex(currentUser.email);
      await sendCancelServiceEmail(currentUser.email, index);
      setSuccess("Successfully Cancelled Service");
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (e) {

      console.log(e.message);
      setFeedback({
        message: handleLoginError(e.message),
        variant: "danger",
      });
    }
    setLoading(false);
  };

  // const hasCanceled = () => {

  // }

  const CancelSubscription = async (subId, userId) => {
    return await postWithCredentials(SERVER_URL + "updateSubscription", {
      subId,
      userId,
      cancellingSubs: true,
    })
      .then(() => {
        // These line wait till 2 mins to allow next cancellation.
        var date = new Date();
        date.setTime(date.getTime() + 60 * 2000);
        setCookie("cancelService", "yes", { expires: date, path: "/" });
      })
      .catch((err) => {
        // console.log(err);
        throw new Error("Failed to cancel subscription");
      });
  };

  const helpContent = (
    <>
      This page requires you to fill in your password and 2FA code obtained from
      your smartphone. Once you confirm that you want to cancel your
      subscription, your subscription will expire at the end of your billing
      period.
    </>
  );

  return (
    <ComponentCard title="Cancel Service" helpContent={helpContent}>
      {success && <Alert variant="success">{success}</Alert>}
      {feedback && <FeedbackAlert feedback={feedback} />}
      {/* If the person has cancelled recently then different words would be displayed. */}
      {cookies.cancelService === "yes" ? (
        <p>
          You have canceled your subscription recently. Please try again after
          some time.
        </p>
      ) : (
        <div>
          <p>
            We're sorry to hear that you've decided to cancel your service. 
            Any changes to your account will take
            effect at the <strong>beginning of the next billing cycle</strong>. 
            Please keep in mind that canceling your
            service means that you'll be returned to the{" "}
            <strong>FREE service</strong>, which only allows decrypting. We
            appreciate your understanding and thank you for using our service.
          </p>
          <p>
            To cancel your service, you must provide 2-Factor Authentication (2FA). 
            If you haven't registered for 2FA yet, click <Link to="/set-google-auth">here</Link>.
          </p>
        </div>
      )}
      <Form onSubmit={handleSubmit} className="w-100 mt-3">
        <Form.Group id="sponsor_email" className="row">
          <Form.Label column>Password</Form.Label>
          <Col lg={10}>
            <div className="pass-wrapper">
              <Form.Control
                type={passwordShown ? "text" : "password"}
                ref={passwordRef}
                placeholder="Password"
                required
              />
              <Col className="iconButton">
                <FontAwesomeIcon onClick={togglePassword}
                  icon={passwordShown ? "eye-slash" : "eye"}
                />
              </Col>
            </div>
          </Col>
        </Form.Group>
        {haveSecret ? (
          <Form.Group id="2FA_code" className="mb-3 row">
            <Form.Label column>2FA Code</Form.Label>
            <Col lg={10}>
              <Form.Control 
                type="text" 
                ref={codeRef}
                placeholder="2FA Code"
                required />
            </Col>
          </Form.Group>
        ) : null}
        <Button
          type="submit"
          className="w-100 mb-1"
          disabled={loading || cookies.cancelService === "yes"}
          data-mdb-ripple-color="dark"
        >
          Cancel Service
        </Button>
      </Form>
    </ComponentCard>
  );
}
