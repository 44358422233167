import CryptoJS from "crypto-js";

/**
 * Number of bytes to read off of the beginning of the files for generating proofs and file identifiers
 */
const BYTES_TO_READ = 32;

/**
 * Size of the initial value vector in bytes
 */
const IV_BYTES = 16;

// Silce size
const SLICE_SIZE = 10 * 1024 * 1024;

// New encypt function with different logic
// The fuction combines oldEncrypt1 and hashinital
export function oldEncrypt1(file, setDownloadProgress) {

    async function onData(res, rej) {
      let start = 0;
      let encryptedBlobParts = [];
      // Generate a random key and initial value vector
      let randomKey = new Uint8Array(BYTES_TO_READ);
      let originalKey = bytesToWordArray(crypto.getRandomValues(randomKey));
      let iv = new Uint8Array(IV_BYTES);
      iv = bytesToWordArray(crypto.getRandomValues(iv));
  
      let aesEnc = CryptoJS.algo.AES.createEncryptor(originalKey, {
        mode: CryptoJS.mode.CFB,
        iv: iv,
      });
  
      
      let initWA, initEnc;  // Variables to store the initial raw and encrypted slices

      while (start < file.size) {
        let slice = await readSlice(file, start, SLICE_SIZE);
        let wordArrayInput = CryptoJS.lib.WordArray.create(slice);
        let encryptedSlice = aesEnc.process(wordArrayInput);
    
        if (start === 0) {
          initWA = wordArrayInput;  // Store the initial raw slice
          initEnc = encryptedSlice;  // Store the initial encrypted slice
        }
    
        encryptedBlobParts.push(new Blob([wordArrayToBytes(encryptedSlice)]));
        start += SLICE_SIZE;
        setDownloadProgress({
          loading: true,
          now: Math.round((start / file.size) * 100),
        });
      }
    
      encryptedBlobParts.push(new Blob([wordArrayToBytes(aesEnc.finalize())]));
      let finalEncryptedBlob = new Blob(encryptedBlobParts, { type: "application/octet-stream" });
    
      // Calculate hashes from the initial raw and encrypted slices
      let initHashed = hashInitial(initWA);  // Hash the initial raw slice
      let xHashed = hashInitial(initEnc);    // Hash the initial encrypted slice
    
      let x2Hashed = CryptoJS.SHA256(xHashed);
      let xOrKey = performXOR(xHashed, originalKey);
    
      // Generate OTP and perform XOR
      let otp = generateCode(12).toString();
      xOrKey = performXOR(xOrKey, CryptoJS.SHA256("" + otp));
    
      let data = [x2Hashed, xOrKey, initHashed, iv, otp, finalEncryptedBlob];
      res(data);
    }
  
    return new Promise((res, rej) => {
      onData(res, rej);
    });
  }

  export async function getFileIdentifier(file) {
    async function onData(res, rej) {
        try {
            let slice = await readSlice(file, 0, SLICE_SIZE);
            let encryptedData = CryptoJS.lib.WordArray.create(slice);
            let xHashed = hashInitial(encryptedData); 
            let x2Hashed = CryptoJS.SHA256(xHashed);
            res(x2Hashed);
        } catch {
            rej("Failed to get file identifier.");
        }
    }

    return new Promise((res, rej) => {
        onData(res, rej);   
    })
}
            

  
/**
 * Generates alphanumeric code with length characters
 * @param {int} length - length of code
 * @returns {string} - randomized code
 */
function generateCode(length) {
    let chars = "-+()1234567890";
    let code = "";
    for (let i = 0; i < length; i++) {
      code += chars[Math.floor(Math.random() * chars.length)];
    }
    return code;
  } 

  /**
 * Converts an array of 8 bit data into a CryptoJS WordArray
 * @param {Array} bytes - Any type of array containing 8 bit numerical data
 * @returns {WordArray} - CryptoJS word array, consisting of 32-bit words
 */
function bytesToWordArray(bytes) {
    var words = [];
    for (var i = 0; i < bytes.length; ++i) {
      var j = 24 - (i % 4) * 8;
      words[i >>> 2] |= bytes[i] << j;
    }
    return CryptoJS.lib.WordArray.create(words, bytes.length);
  }
  
  /**
   * Converts a CryptoJS WordArray into an 8 bit array
   * @param {WordArray} wa - 8 bit data encoded into a CryptoJS word array (32 bit words)
   * @returns {Uint8Array} - 8 bit array containing the hex data
   */
  function wordArrayToBytes(wa) {
    let bytes = [];
    for (var i = 0; i < wa.sigBytes; ++i) {
      var j = 24 - (i % 4) * 8;
      bytes.push((wa.words[i >>> 2] >>> j) & 0xff);
    }
    return new Uint8Array(bytes);
  }
  
  /**
   * XORs two WordArrays, clamping the length to equal the smaller WordArray.
   * @param {WordArray} a - First WordArray to XOR
   * @param {WordArray} b - Second WordArray to XOR
   * @returns {WordArray} - XORed WordArray
   */
  function performXOR(a, b) {
    let XORSize = Math.min(a.words.length, b.words.length);
    let c = [];
    for (let i = 0; i < XORSize; ++i) {
      c.push(a.words[i] ^ b.words[i]);
    }
    return CryptoJS.lib.WordArray.create(c, Math.min(a.sigBytes, b.sigBytes));
  }

  export function oldDownloadFile1(blob, filename) {
    // Give the blob a URL
    let url = window.URL.createObjectURL(blob);
  
    // Create a click element, attach the blob, and download the file through a click
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = filename;
    a.click();
  
    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  /**
 * Gets a unique file identifier from the encrypted file
 * @param {WordArray} encryptedDataWA - Encrypted file data WordArray
 * @returns {WordArray} - Returns first 32 bytes of the encrypted file, hashed with SHA256
 */
function hashInitial(encryptedDataWA) {
    let encryptedWords = encryptedDataWA.words.slice(0, BYTES_TO_READ >>> 2);
    while (encryptedWords.length < BYTES_TO_READ >>> 2) {
      encryptedWords.push(0);
    }
    let x = CryptoJS.lib.WordArray.create(encryptedWords, BYTES_TO_READ);
    let xHashed = CryptoJS.SHA256(x);
    return xHashed;
  }

  /**
 * Reads slice of user's uploaded file.
 * @param {File} file - file containing data to be read
 * @param {int} start - starting position of slice to be read
 * @param {int} size - size of slice to be read
 */
async function readSlice(file, start, size) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const slice = file.slice(start, start + size);
  
      fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(slice);
    });
  }

  async function readSliceToBase64(file, start, size) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        const slice = file.slice(start, start + size);

        fileReader.onload = () => {
            // Convert the ArrayBuffer to Base64
            const base64 = arrayBufferToBase64(fileReader.result);
            resolve(base64);
        };
        fileReader.onerror = reject;

        // Read the slice as an ArrayBuffer
        fileReader.readAsArrayBuffer(slice);
    });
}

function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary); // Encode binary string to Base64
}

  
  