import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Button,
  Alert,
  Row,
  Col,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import Axios from "axios";
import { auth } from "../firebase";
import { verifyCode } from "../functions/verify";
import { getUserFromEmail, getUserFromSecondIndex } from "../functions/getUser";
import ComponentCard from "./new/ComponentCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function AcceptSponsorshipReq() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [codeError, setCodeError] = useState("");
  const [codeSuccess, setCodeSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [listOfEmployee, setListOfEmployee] = useState([]);
  const navigate = useNavigate();
  const { generateEmailIndex } = useAuth();
  const [requestCount, setRequestCount] = useState(0);
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setError("");
    navigate("/dashboard");
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    setCodeError("");
    setLoading(true);
    const userData = await getUserFromEmail(auth.currentUser.email);
    const verified = await verifyCode(codeRef.current.value, userData.user_id);
    if (verified) {
      setCodeSuccess("Code correct.");
      setTimeout(() => {
        setCodeSuccess("");
        setLoading(false);
        setShow(false);
      }, 1000);
    } else {
      setCodeError("Incorrect code.");
      setLoading(false);
    }
  };

  const codeRef = useRef();

  useEffect(() => {
    (async () => {
      await loadPage();
    })();
  }, []);

  const loadPage = async () => {
    try {
      setShow(true);
      const userEmail = auth.currentUser.email;
      const userData = await getUserFromEmail(userEmail);
      const { SponsorshipRequestsList } = userData;
      if (SponsorshipRequestsList) {
        setRequestCount(Object.keys(SponsorshipRequestsList).length);
        let list = [];
        for (const property in SponsorshipRequestsList) {
          const listData = await getUserFromSecondIndex(property);
          list.push(listData);
        }
        setListOfEmployee(list);
      }
    } catch (err) {
      alert("Error displaying page.");
      // console.log(err);
    }
  };

  const handleAccept = async (employee) => {
    const currentUser = auth.currentUser;
    try {
      setError("");
      setLoading(true);
      // check if employee is still in the SponsorShipRequestsList
      const userData = await getUserFromEmail(currentUser.email);
      const { SponsorshipRequestsList } = userData;
      if (
        !SponsorshipRequestsList ||
        !Object.hasOwn(SponsorshipRequestsList, employee.second_index)
      ) {
        setError("The user is no longer requesting sponsorship.");
        setTimeout(() => {
          navigate("/accept-sponsorship-req");
        }, 3000);
        return;
      }
      // check if employee is already being sponsored
      let userSponsored = await checkSponsorshipStatus(
        currentUser.id,
        employee.second_index
      );
      if (userSponsored) {
        setError("You are already sponsoring this user");
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
        return;
      }
      await removeSponsorRequest(currentUser.uid, employee.second_index, "");
      await updateSponsorship(
        employee.user_id,
        currentUser.uid,
        employee.second_index,
        generateEmailIndex(currentUser.email)
      );
      // unnecessary as updateSponsorship already handles this
      // await sendAcceptanceEmail(currentUser.email, employee.email);
      setListOfEmployee(listOfEmployee.filter((e) => e !== employee));
    } catch {
      setError("Failed to accept");
      setLoading(false);
    }
  };

  const handleDecline = async (employee) => {
    const currentUser = auth.currentUser;
    await removeSponsorRequest(currentUser.uid, employee.second_index, "declined");
    setListOfEmployee(listOfEmployee.filter((e) => e !== employee));
  };

  const updateSponsorship = async (
    employeeId,
    sponsorId,
    employeeSecondIndex,
    sponsorSecondIndex
  ) => {
    await postWithCredentials(SERVER_URL + "updateSponsorship", {
      employeeId: employeeId,
      sponsorId: sponsorId,
      employeeSecondIndex: employeeSecondIndex,
      sponsorSecondIndex: sponsorSecondIndex,
    })
      .then(() => {
        setSuccess("Accepted Sponsorship");
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  /*
  // unnecessary as updateSponsorship already handles this
  const sendAcceptanceEmail = async (senderEmail, receiverEmail) => {
    // send email to notify employee that the request is accepted
    await postWithCredentials(SERVER_URL + "sendResponseEmail", {
      sender: senderEmail,
      receiver: receiverEmail,
    })
      .then(() => {
        // console.log('Sent email');
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  */

  const checkSponsorshipStatus = async (id, secondIndex) => {
    return await postWithCredentials(SERVER_URL + "checkSponsorshipStatus", {
      sponsorId: id,
      employeeSecondIndex: secondIndex,
    })
      .then((response) => {
        if (response.data.status === true) {
          // console.log('You are already sponsoring this user');
        }
        return response.data.status;
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  const removeSponsorRequest = async (id, secondIndex, reason) => {
    await postWithCredentials(SERVER_URL + "removeFromSponsorshipRequestList", {
      sponsorId: id,
      employeeSecondIndex: secondIndex,
      removalReason: reason
    }).catch((err) => {
      // console.log(err);
      setLoading(false);
    });
  };

  const content = (
    <>
      {success && <Alert variant="success">{success}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      {requestCount === 0 && "You have no requests"}
      <h4 className="mb-1 text-center">Number of Requests: {requestCount}</h4>

      <ListGroup className="mb-3">
        {listOfEmployee.map((employee, index) => {
          return (
            <ListGroup.Item key={index + 1} id={index + 1}>
              <Form.Group id={employee.email}>
                <Row>
                  <Col xs={3}>
                    <Form.Label column>
                      {employee.first_name + " " + employee.last_name}
                    </Form.Label>
                  </Col>
                  <Col xs={5}>
                    <Form.Label column>{employee.email}</Form.Label>
                  </Col>
                  <Col xs={2}>
                    <Button
                      disabled={loading}
                      className="w-100"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleAccept(employee);
                      }}
                    >
                      Accept
                    </Button>
                  </Col>
                  <Col xs={2}>
                    <Button
                      disabled={loading}
                      className="w-100"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDecline(employee);
                      }}
                    >
                      Decline
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
  const helpContent = (
    <p className="mb-1">
      Accepting a sponsorship request will allow the sponsored individual to use
      your purchased subscription. So when either you or they encrypt a file, it
      will use your service.
    </p>
  );

  return (
    <>
      <ComponentCard
        title="Accept or Decline Sponsorship Requests"
        helpContent={helpContent}
      >
        {content}
      </ComponentCard>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            2FA is Required to Accept or Decline Sponsorship Requests
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleContinue}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Please enter your Google Authenticator code to continue.
                If you haven't registered for 2FA yet, click <Link to="/set-google-auth">here</Link>.
              </Form.Label>
              {codeError && <Alert variant="danger">{codeError}</Alert>}
              {codeSuccess && <Alert variant="success">{codeSuccess}</Alert>}
              {/* {isSponsor && removed && (
                <Alert variant="success">{removed}</Alert>
              )} */}
              <Form.Control id="modal-code-input" type="text" ref={codeRef} required />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button id="modal-cancel-button" variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button id="modal-continue-button" variant="primary" type="submit" disabled={loading}>
              Continue
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
