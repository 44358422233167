import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Col, Row, Collapse, FormControl } from "react-bootstrap";
import { useAuth, postWithCredentials } from "../contexts/AuthContext";
import Axios from "axios";
import { verifyCode } from "../functions/verify";
import {
  getUserFromEmail,
  getUserFromId,
} from "../functions/getUser";
import ComponentCard from "./new/ComponentCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import "./Signup.css";
import FeedbackAlert from "./new/FeedbackAlert";

library.add(faEye, faEyeSlash);
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const VERIFIED_STATUS = {
  NOT_VERIFIED: 0,
  TFA_VERIFIED: 1,
  PAS_VERIFIED: 2,
};

export default function DeleteAccount() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const codeRef = useRef(null);
  const { currentUser, login, logout } = useAuth();
  const [passwordShown, setPasswordShown] = useState(false);
  const [haveSecret, setHaveSecret] = useState(false);
  const [authenticatorVerified, setAuthenticatorVerified] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(
    VERIFIED_STATUS.NOT_VERIFIED
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [nextDisable, setNextDisable] = useState(true);

  // first check if user have signed up for google auth while init this page
  useEffect(() => {
    const uid = window.location.pathname.slice(
      window.location.pathname.lastIndexOf("/") + 1
    );

    postWithCredentials(SERVER_URL + "have2fa", {
      user_id: uid,
    })
      .then((res) => {
        // console.log(res.data.haveSecret);
        setHaveSecret(res.data.haveSecret);
        setNextDisable(false);
      })
      .catch(async (err) => {
        setFeedback({
          message:
            "Oops, something went wrong. We cannot delete your account at this point, please contact us.",
          variant: "danger",
        });
      });
  }, []);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // if user has google auth, check it, if user does not have google auth, skip it
  const onSubmit2FA = async () => {
    // console.log(email);
    // user does not have google auth
    if (!haveSecret) {
      setAuthenticatorVerified(true);
      setVerifiedStatus(VERIFIED_STATUS.TFA_VERIFIED);
      setFeedback({
        message: "Google Authenticator Not Required",
        variant: "success",
      });
    }
    // user has google auth
    else {
      const user_id = window.location.pathname.slice(
        window.location.pathname.lastIndexOf("/") + 1
      );
      // console.log(user_id);
      const userInfo = await getUserFromId(user_id);
      // console.log(userInfo);
      const verified = await verifyCode(
        codeRef.current.value,
        userInfo.user_id
      );
      // verification error
      if (!verified) {
        setAuthenticatorVerified(false);
        setVerifiedStatus(VERIFIED_STATUS.NOT_VERIFIED);
        setFeedback({
          message: "Wrong code for Google Authenticator. Please check again.",
          variant: "warning",
        });
        return;
      }
      // verification success
      else {
        setAuthenticatorVerified(true);
        setVerifiedStatus(VERIFIED_STATUS.TFA_VERIFIED);
        setFeedback({
          message: "Google Authenticator Code verified.",
          variant: "success",
        });
      }
    }
  };

  const handleLoginError = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        return "This email is invalid.";
      case "auth/user-disabled":
        return "This user has been disabled.";
      case "auth/user-not-found":
        return "User not found.";
      case "auth/wrong-password":
      case "auth/internal-error":
        return "Wrong password.";
      default:
        console.log(errorCode, "here");
        return "An unknown error has occurred.";
    }
  };

  // verify user password and email
  const onSubmitPAS = async () => {
    if (
      emailRef.current.value === "" ||
      emailRef.current.value === null ||
      passwordRef.current.value === "" ||
      passwordRef.current.value === null
    ) {
      setFeedback({
        message: "Please enter your email and password!",
        variant: "danger",
      });
      return;
    }
    // reauthenticate
    const emailLowercase = emailRef.current.value.toLowerCase();
    if (currentUser !== null) {
      // console.log(currentUser);
      const userEmail = currentUser.email;
      if (userEmail !== emailLowercase) {
        setFeedback({
          message:
            "Your email address or password is incorrect, please double check!",
          variant: "danger",
        });
        return;
      }
      // reauthenticate
      const credential = EmailAuthProvider.credential(
        emailLowercase,
        passwordRef.current.value
      );
      try {
        await reauthenticateWithCredential(
          currentUser,
          credential
        );
      } catch (e) {
        setFeedback({
          message:
            "Your email address or password is incorrect, please double check!",
          variant: "danger",
        });
        return;
      }
    } else {
      // user is NOT logged in
      try {
        // user is NOT logged in
        const login_result = await login(
          emailLowercase,
          passwordRef.current.value
        );
        if (login_result) {
          const mes = handleLoginError(login_result);
          setFeedback({
            message: mes,
            variant: "danger",
          });
          return;
        }
      } catch (e) {
        setFeedback({
          message:
            "Your email address or password is incorrect, please double check!",
          variant: "danger",
        });
      }
    }
    const userInfo = await getUserFromEmail(
      emailRef.current.value.toLowerCase()
    );
    setFeedback({
      message: "User Account Deleting..",
      loading: true,
      variant: "info",
    });
    // console.log(userInfo);
    await deleteAllUserData(userInfo);
  };

  const deleteAllUserData = async ({
    user_id,
    subscription_id,
    email,
    FPP,
  }) => {
    // console.log(FPP, "THIS IS FPP");
    if (FPP === 1) {
      // cancel subscription first
      await postWithCredentials(`${SERVER_URL}updateSubscription`, {
        userId: user_id,
        subId: subscription_id,
        cancellingSubs: true,
      })
        .then(() => {
          // console.log("cancelled subscription");
        })
        .catch(() => {
          setFeedback({
            message:
              "Oops, something went wrong, please refresh the page, if still not working, please contact us",
            variant: "info",
          });
          return;
        });
    }

    // delete account second
    await postWithCredentials(SERVER_URL + "deleteAllUserData", {
      userId: user_id,
      email,
    })
      .then((res) => {
        setNextDisable(true);
        const delete_date = new Date(res.data.deletion_date);
        setFeedback({
          message: `Your account has been marked for deletion.
          If you did this by accident, or want to recover your account, you can 
          log back in before the deletion date: ${delete_date.toDateString()}.`,
          variant: "success",
        });
        setTimeout(() => {
          logout();
        }, 4000);
      })
      .catch((err) => {
        setFeedback({
          message:
            "Oops, something went wrong, please refresh the page, if still not working, please contact us",
          variant: "info",
        });
        return;
      });
  };

  const helpContent = (
    <>
      <p className="mb-1">
        To delete your account, you need to provide your email address, your
        password, as well as a Google Authenticator 2FA code from your phone if
        you set 2FA up. Once you click <b>Delete</b>, your account will be
        marked for deletion.
      </p>
      <p className="mb-1">
        If you change your mind before the date of deletion, you can re-activate
        your account by logging back in. The login page will ask to confirm that
        you want to reactivate your account, and then your account will be
        reactivated. While your account is deactivated, you cannot create a new
        account with the same email.
      </p>
      <p className="mb-0">
        <b>Note</b>: Please note that deleting your account will result in the
        cancellation of your subscription at the end of the current billing
        period. If you choose to reactivate your account in the future and wish
        to continue using our service, you will need to select a new
        subscription.
      </p>
      <p className="mb-0">
        <b>Issues</b>: If you are experiencing issues with your 2FA always being
        incorrect, it may be due to an improper 2FA setup. We kindly suggest
        completing the 2FA registration process, and then try to delete your
        account again. We hope this helps resolve the issue.
      </p>
    </>
  );

  return (
    <ComponentCard title="Delete Account" helpContent={helpContent}>
      <Form
        noValidate
        onSubmit={(e) => {
          // prevent page from refreshing
          e.preventDefault();

          switch (verifiedStatus) {
            case VERIFIED_STATUS.TFA_VERIFIED:
              onSubmitPAS(e);
              break;
            case VERIFIED_STATUS.NOT_VERIFIED:
              onSubmit2FA(e);
              break;
            default:
              console.error("VERIFIED IN AN UNDEFINED STATE");
              break;
          }
        }}
      >
        <Form.Group className="row">
          {haveSecret ? (
              <div className="mb-3 row pe-0">
                <Form.Label column className="text-center text-lg-end px-0 ms-2">
                  Google Auth Code
                </Form.Label>
                <Col lg={9} xl={9} xxl={10} className="pe-0">
                  <FormControl
                    name="codeRef"
                    id="google-code"
                    ref={codeRef}
                    required
                    disabled={verifiedStatus >= VERIFIED_STATUS.TFA_VERIFIED}
                  />
                </Col>{" "}
              </div>
          ) : null}
        </Form.Group>
        <Collapse in={authenticatorVerified} className="my-0">
          <div>
            <Form.Group className="mb-3 row">
              <Form.Label column className="text-center text-lg-end px-0">
                Email
              </Form.Label>
              <Col sm={12} lg={9} xl={9} xxl={10}>
                <Row>
                  <Col>
                    <Form.Control
                      name="email"
                      id="email"
                      value={email}
                      ref={emailRef}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="example@email.com"
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group className="mb-3 row">
              <Form.Label column className="text-center text-lg-end px-0">
                Password
              </Form.Label>
              <Col sm={12} lg={9} xl={9} xxl={10}>
                <Row>
                  <Col>
                    <div className="pass-wrapper">
                      <input
                        className="form-control"
                        name="password"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        autoComplete="new-password"
                        value={password}
                        ref={passwordRef}
                        placeholder="**********"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                      <span className="pass-icon" onClick={togglePassword}>
                        <FontAwesomeIcon
                          icon={passwordShown ? "eye" : "eye-slash"}
                        />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
          </div>
        </Collapse>
        <Button
          type="submit"
          disabled={(feedback && feedback.loading) || nextDisable}
          className="w-100"
        >
          Next
        </Button>
      </Form>
      <FeedbackAlert feedback={feedback} className="mb-0 mt-2" />
    </ComponentCard>
  );
}
