import React, { useRef, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { handleLoginError } from "./Login";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import LoadingButton from "./LoadingButton";
import FeedbackAlert from "./new/FeedbackAlert";
import ReactPasswordChecklist from "react-password-checklist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./Signup.css";
import ComponentCard from "./new/ComponentCard";
library.add(faEye, faEyeSlash);

export default function ChangePassword() {
  const emailRef = useRef();
  const oldPasswordRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordShownOld, setPasswordShownOld] = useState(false);
  const [passwordShownNew, setPasswordShownNew] = useState(false);
  const [passwordShownNewC, setPasswordShownNewC] = useState(false);

  const togglePasswordOld = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShownOld(!passwordShownOld);
  };
  const togglePasswordNew = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShownNew(!passwordShownNew);
  };
  const togglePasswordNewC = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShownNewC(!passwordShownNewC);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const promises = [];
    setFeedback({
      loading: true,
      message: "Verifying your credentials...",
      variant: "info",
    });

    // reauthenticate
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      oldPasswordRef.current.value
    );
    try {
      await reauthenticateWithCredential(
        currentUser,
        credential
      );
    } catch (e) {
      setFeedback({
        message: handleLoginError(e.code),
        variant: "danger",
      });
      return;
    }

    //Check password
    var regExp = /[a-zA-Z]/g;
    var hasNumber = /\d/;
    if (
      passwordRef.current.value.length < 10 ||
      !regExp.test(passwordRef.current.value) ||
      !hasNumber.test(passwordRef.current.value)
    ) {
      setFeedback({
        message: "New password too weak",
        variant: "danger",
      });
      return;
    }
    // check if the new passwords match
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      setFeedback({
        message: "Passwords do not match",
        variant: "danger",
      });
      return;
    }

    // update email and password
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        setFeedback({
          loading: true,
          message: "Changed account details! Redirecting...",
          variant: "success",
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      })
      .catch((err) => {
        setFeedback({
          message: "Failed to update account",
          variant: "danger",
        });
      })
      .finally(() => {
      });
  };

  const helpContent = (
    <>
      By providing your old password, you can update your password on this page.
      Your new password must adhere to the security requirements, which are
      shown when you enter your new password. After filling in the information,
      you may click <b>Update</b> to submit the changes to your account. After
      successfully updating, you will need to enter your new password to log
      back in.
    </>
  );

  return (
    <ComponentCard title={"Reset Password"} helpContent={helpContent}>
      <Row>
        <Col>
          <FeedbackAlert feedback={feedback} />
          <Form onSubmit={handleSubmit} className="w-100 text-start">
            <Form.Group id="email" className="mt-2">
              <Row>
                <Col
                  xs={12}
                  lg={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                >
                  Email
                </Col>
                <Col>
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    required
                    defaultValue={currentUser.email}
                    placeholder="example@mail.com"
                    disabled
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="row mt-2">
              <Form.Label column className="text-lg-end text-center px-0">
                Current Password
              </Form.Label>
              <Col xs={12} lg={10}>
                <div className="pass-wrapper">
                  <Form.Control
                    type={passwordShownOld ? "text" : "password"}
                    className="form-control"
                    id="old-password"
                    ref={oldPasswordRef}
                    placeholder="Old Password"
                  />
                  <div className="iconButton" onClick={togglePasswordOld}>
                    <FontAwesomeIcon
                      icon={passwordShownOld ? "eye-slash" : "eye"}
                    />
                  </div>
                </div>
              </Col>
            </Form.Group>
            <Form.Group id="password" className="mt-2">
              <Row>
                <Form.Label column className="text-center text-lg-end px-0">
                  New Password
                </Form.Label>
                <Col xs={12} lg={10}>
                  <div className="pass-wrapper">
                    <Form.Control
                      type={passwordShownNew ? "text" : "password"}
                      className="form-control"
                      id="new-password"
                      ref={passwordRef}
                      placeholder="**********"
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      required
                    />
                    <div className="iconButton" onClick={togglePasswordNew}>
                      <FontAwesomeIcon
                        icon={passwordShownNew ? "eye-slash" : "eye"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>
            <div
              className="form-group"
              style={{
                textAlign: "left",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              <span className="form-group-label"></span>

              <ReactPasswordChecklist
                rules={["minLength", "letter", "number"]}
                minLength={10}
                value={password}
                className="form-group-input"
              />
            </div>
            <Form.Group id="password-confirm" className="mt-2">
              <Row>
                <Form.Label column className="text-center text-lg-end px-0">
                  Confirm Password
                </Form.Label>
                <Col xs={12} lg={10}>
                  <div className="pass-wrapper">
                    <Form.Control
                      type={passwordShownNewC ? "text" : "password"}
                      className="form-control"
                      id="confirm-password"
                      ref={passwordConfirmRef}
                      placeholder="**********"
                    />{" "}
                    <div className="iconButton" onClick={togglePasswordNewC}>
                      <FontAwesomeIcon
                        icon={passwordShownNewC ? "eye-slash" : "eye"}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form.Group>
            <Row>
              <Col>
                <LoadingButton
                  loading={feedback && feedback.loading}
                  className="mt-3 w-100"
                  type="submit"
                >
                  Update
                </LoadingButton>
              </Col>
            </Row>
            {/* <p href="/forgot-password">Forget password?</p> */}
          </Form>
        </Col>
      </Row>
    </ComponentCard>
  );
}
