import React, { useEffect, useState } from "react";
import { Card, Button, Stack, Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

import video from "../assets/video/secure-file-sharing.mp4";

const featuresList = [
  {
    heading: "No password required",
    description:
      "In order to share an encrypted file, the original file owner should not need to share his/her password, nor share the encryption key that was used to encrypt the file.",
  },
  {
    heading: "Two factor authentication",
    description:
      "Multi-Factor Authentication, providing user convenience and superior security.",
  },
  {
    heading: "Expiry dates",
    description:
      "Files are not available after expiry date to enhance security.",
  },
  {
    heading: "Decryption limits",
    description:
      "Encryption key is formed solely within end-user's trusted device and is erased upon reaching the set limit.",
  },
  {
    heading: "Notifications",
    description:
      "Notification emails are sent to users when changes are made to encrypted files.",
  },
  {
    heading: "Sponsorships",
    description: "Users may share encryption limits with others.",
  },
];

export default function Home() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (!auth.currentUser) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
    // isMaintenance;
  }, [loggedIn]);

  const features = featuresList.map(({ heading, description }) => {
    return (
      <div
        style={{
          flex: "1 400px",
        }}
        className="m-1"
        key={heading}
      >
        <Card body className="w-100 h-100">
          <h6 className="text-uppercase small">
            <b>{heading}</b>
          </h6>
          <p>{description}</p>
        </Card>
      </div>
    );
  });

  const quickLinks = [
    {
      name: loggedIn ? "Dashboard" : "Sign In",
      action: () => {
        navigate(loggedIn ? "/dashboard" : "/login");
      },
    },
    {
      name: loggedIn ? "Decrypt" : "Decrypt Without Sign In",
      action: () => {
        navigate(loggedIn ? "/decrypt" : "/decrypt2");
      },
    },
    {
      name: "User Guide",
      action: () => {
        const link = document.createElement("a");
        link.download = "Medical SFS User Guide";
        link.href = "/med-doc.pdf";
        link.click();
      },
    },
    {
      name: "Contact Us",
      action: () => {
        navigate("/contact");
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          minHeight: "40vh",
          backgroundImage: `url(/img/CYN_Cynorix.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          boxShadow: "inset 0px 0px 15px 5px rgba(0, 0, 0, 0.5)",
          margin: "0 auto",
        }}
        className="py-4 mb-4 d-flex align-items-center"
      >
        <Container>
          <Row>
            <Col xs={12} sm={12} md={6} lg={5}>
              <Card
                className="py-5 px-4"
                style={{
                  border: "none",
                  backgroundColor: "rgba(255, 255, 255, 0.95)",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <h3 className="mb-3">
                  <b>Secure File Sharing</b>
                </h3>
                <Stack gap={2}>
                  {quickLinks.map(({ name, action }) => (
                    <Button className="w-100" onClick={action} key={name}>
                      {name}
                    </Button>
                  ))}
                </Stack>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="mb-2">
          <Col>
            <h4>
              <b>
                Why is it so{" "}
                <span style={{ color: "#951212" }}>
                  <u>Great</u>?
                </span>
              </b>
            </h4>
          </Col>
        </Row>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
          {features}
        </div>
        <Row className="mt-3 mb-2">
          <h4>
            <b>About Secure File Sharing</b>
          </h4>
        </Row>
        <Row className="my-2">
          {/* Video  */}
          <Col xs={12}>
            <Card>
              <video controls>
                <source src={video} type="video/mp4"></source>
              </video>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
