import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Col, Row, Alert, Modal } from "react-bootstrap";
import { auth } from "../firebase";
import { useAuth, postWithCredentials} from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { verifyCode } from "../functions/verify";
import { getUserFromEmail } from "../functions/getUser";
import LoadingButton from "./LoadingButton";
import ComponentCard from "./new/ComponentCard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function SelectSponsor() {
  const emailRef = useRef("");
  const codeRef = useRef("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [FPP, setFPP] = useState(false);
  const navigate = useNavigate();
  const { generateEmailIndex } = useAuth();

  ////////Modal
  const [show, setShow] = useState(false);

  useEffect(() => {
    const renderAccessStatus = async () => {
      const userEmail = auth.currentUser.email;
      const user = await getUserFromEmail(userEmail);
      // console.log(user);
      setFPP(user.FPP);
      if (Object.hasOwn(user, "listOfSponsorship") || user.FPP === 2) {
        handleShow();
      }
    };
    renderAccessStatus();
  }, []);

  const handleClose = () => {
    setShow(false);
    setError("");
    navigate("/dashboard");
  };
  const handleShow = () => {
    setShow(true);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userEmail = auth.currentUser.email;
    const sponsorEmail = emailRef.current.value;
    setError("");
    setLoading(true);

    if (userEmail === sponsorEmail) {
      setError("Unable to request sponsorship. Please provide a valid email.");
      setLoading(false);
      return;
    }
    try {
      const sponsorData = await getUserFromEmail(sponsorEmail);
      if (sponsorData.FPP !== 1) {
        setError(
          "Selected account does not have permission to provide sponsorship."
        );
        setLoading(false);
        return;
      }
    } catch (err) {
        setError(
          "Selected sponsor does not exist, please provide a valid email."
        );
      setLoading(false);
      return;
    }
    try {
      const verified = await verifyCode(
        codeRef.current.value,
        auth.currentUser.uid
      );
      if (!verified) {
        setError("Incorrect Code.");
        setLoading(false);
        return;
      }
      // no error:
      const sender_email_index = generateEmailIndex(userEmail);
      const receiver_email_index = generateEmailIndex(emailRef.current.value);
      await sendSponsorshipRequestEmail(
        userEmail,
        emailRef.current.value,
        sender_email_index,
        receiver_email_index
      );
      setSuccess("Request Sent");
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setError("An error has occurred.");
    }
  };

  const sendSponsorshipRequestEmail = async (
    sender,
    receiver,
    senderEmailIndex,
    receiverEmailIndex
  ) => {
    await postWithCredentials(SERVER_URL + "sendSponsorshipRequestEmail", {
      sender: sender,
      receiver: receiver,
      sender_email_index: senderEmailIndex,
      receiver_email_index: receiverEmailIndex,
    })
      .then((response) => {
        // console.log('Email sent successfully');
      })
      .catch((err) => {
        // console.log(err);
        throw err;
      });
  };

  const helpContent = (
    <p className="mb-1">
      If the user wants to ask another person to cover the current user’s
      service fees, they can click Request Sponsorship. On this page, they are
      required to provide the sponsor’s email address, as well as a 2FA code
      from their phone. This is set up in Register 2-Factor Authentication. If
      the user hasn’t registered for 2FA, they can click the link on the Request
      Sponsorship page, which will take them to the page to set up 2FA.
    </p>
  );

  return (
    <ComponentCard title={"Select A Sponsor"} helpContent={helpContent}>
      <Row>
        <Col>
          {success && <Alert variant="success">{success}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <p>Send a request to a sponsor to cover service fees. To send a sponsorship request, you must provide 2-Factor Authentication (2FA). 
            If you haven't registered for 2FA yet, click <Link to="/set-google-auth">here</Link>.</p>
          {FPP === 1 && (
            <p>
              <b>Notice: </b>
              You are currently subscribed. Note that becoming sponsored will
              end your current subscription.
            </p>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="sponsor_email">
              <Row className="mb-2">
                <Col
                  lg={2}
                  md={3}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                >
                  <label htmlFor="fileUses">Enter Sponsor's Email</label>
                </Col>
                <Col lg={10} md={9} sm={12} xs={12}>
                  <input
                    name="uses"
                    className="form-control"
                    id="sponsor-email"
                    type="email"
                    ref={emailRef}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group id="2FA_code">
              <Row className="mb-2">
                <Col
                  lg={2}
                  md={3}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                  className={`mb-2 mb-lg-0 justify-content-center justify-content-lg-end`}
                >
                  <label htmlFor="fileUses">Enter 2FA Code</label>
                </Col>
                <Col lg={10} md={9} sm={12} xs={12}>
                  <input
                    name="uses"
                    className="form-control"
                    id="2fa-code"
                    type="text"
                    ref={codeRef}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <LoadingButton
              loading={loading}
              className="mt-3 w-100"
              id="request-button"
              type="submit"
            >
              Request Sponsorship
            </LoadingButton>
          </Form>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>You Do Not Have Access To This Page</Modal.Title>
        </Modal.Header>
        <Modal.Body>You will now be redirected to the dashboard</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </ComponentCard>
  );
}
