import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CloseButton,
  Col,
  Form,
  ListGroup,
  Row
} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import ComponentCard from "./new/ComponentCard";
import FileUploadBox from "./new/FileUploadBox";

import * as data from "../assets/json/notifications.json";

import {
  DECRYPTION_NEEDS_VERIFICATION,
  DECRYPTION_NOT_FOUND,
  DECRYPTION_SUCCESS,
  CONNECTION_ERROR,
  DECRYPTION_BAD_PROOF,
  SERVER_GETUSES_SUCCESS,
} from "../assets/js/constants";

import {getFileIdentifier1,oldDecrypt1, decrypt1 } from "../services_decrypt/decyptUtilsNew"
import * as server from "../services_decrypt/server-connector";
import "./DecryptionPage.css";
import AuthDialog from "./AuthDialog";
import { postWithCredentials } from "../contexts/AuthContext"

let canNewDecrypt = "showSaveFilePicker" in window;

const notifications = data;
/**
 * Handles all frontend logic relevant to the decryption flow
 */
class DecryptionPage extends Component {
  /**
   * Initializes state and handlers for buttons
   * @param props - React props
   */
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      number: "",
      email: "",
      timeLeft: 60,
      smsSent: 0,
      count: "",
      message: "",
      status: "",
      displayTime: "5:00",
      sessionTimeLeft: 300,
      sessionDisplayTime: "5:00",
      enableResend: true,
      decryptNotFound: false,
      verificationResent: false,
      verificationRequired: false,
      fileNotEndInCy: false,
      noFileUploaded: false,
      successfulDecrypt: false,
      decryptFail: false,
      decryptWrongCode: false,
      decryptEmptyCode: false,
      show2FADialog: false,
    };
    this.setState = this.setState.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.continuousUpdate = this.continuousUpdate.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.evaluateDecryption = this.evaluateDecryption.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.cancel2FACloseDialog = this.cancel2FACloseDialog.bind(this);
    this.resend2FA = this.resend2FA.bind(this);
  }

  /**
   * Cancels 2FA request upon auth dialog "cancel" button click
   */
  cancel2FACloseDialog = () => {
    this.setState({
      show2FADialog: false,
      verificationResent: false,
      decryptWrongCode: false,
      decryptEmptyCode: false,
    });
  };

  /**
   * Resends the 2FA request when the user asks by re-initiating decrypt and canceling previous 2FA request
   */
  resend2FA = () => {
    this.decryptFile(true);
  };

  updateTime() {
    this.setState({
      timeLeft: Math.max(
        Math.floor((this.state.smsSent - Date.now()) / 1000) + 60,
        0
      ),
    });
    this.setState({
      displayTime:
        Math.floor(this.state.timeLeft / 60) +
        ":" +
        (this.state.timeLeft % 60 < 10 ? "0" : "") +
        (this.state.timeLeft % 60),
    });

    this.setState({
      sessionTimeLeft: Math.max(this.state.sessionTimeLeft - 1, 0),
    });
    this.setState({
      sessionDisplayTime:
        Math.floor(this.state.sessionTimeLeft / 60) +
        ":" +
        (this.state.sessionTimeLeft % 60 < 10 ? "0" : "") +
        (this.state.sessionTimeLeft % 60),
    });
  }

  continuousUpdate() {
    this.updateTime();
    if (this.state.timeLeft === 0) {
      this.setState({ enableResend: true });
    }
    if (this.state.show2FADialog) {
      setTimeout(this.continuousUpdate, 1000);
    }
    if (this.state.sessionTimeLeft === 0) {
      window.location.reload();
      sessionStorage.setItem("reloading", "true");
    }
  }

  componentDidMount() {
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      sessionStorage.removeItem("reloading");
      // emitWarningNotification(notifications.decryption.sessionExpired);
    }
  }

  /**
   * Gets the file identifier and ports it to the server. Begins decryption or sends 2FA request.
   * @param {Boolean} retry - Whether the current request is new or is a resend. Default is false
   */
  async decryptFile(retry = false) {
    let page = this;
    getFileIdentifier1(this.state.file)
      .then((id) => {
        page.setState({ fileInit: id });
        server.getUses(id).then((res) => {
          page.setState({ uses: res.uses });
        });
        return server.getDecryptionData(id);
      })
      .then((res) => {
        for (const key of Object.keys(res)) {
        }

        if (res.message === DECRYPTION_NEEDS_VERIFICATION) {
          // retry
          //   ? emitSuccessNotification(notifications.twoFactor.resent)
          //   : emitWarningNotification(notifications.twoFactor.isRequired);
          retry
            ? this.setState({
                verificationResent: true,
                decryptNotFound: false,
                verificationRequired: false,
                fileNotEndInCy: false,
                noFileUploaded: false,
                successfulDecrypt: false,
                decryptFail: false,
                decryptWrongCode: false,
                decryptEmptyCode: false,
              })
            : this.setState({
                verificationResent: false,
                decryptNotFound: false,
                verificationRequired: false,
                fileNotEndInCy: false,
                noFileUploaded: false,
                successfulDecrypt: false,
                decryptFail: false,
                decryptWrongCode: false,
                decryptEmptyCode: false,
              });

          page.setState({
            xOrKey: res.key,
            iv: res.iv,
            number: res.number,
            count: res.count,
            show2FADialog: true,
            smsSent: Date.now(),
            otp: res.otp,
          });
          if (!retry) {
            page.setState({
              sessionTimeLeft: 300,
              sessionDisplayTime: "5:00",
              enableResend: true,
            });
          } else {
            page.setState({
              enableResend: false,
            });
          }
          server.setTime(this.state.fileInit, this.state.smsSent);
          if (!retry) this.continuousUpdate();
        } else if (res.message === DECRYPTION_NOT_FOUND) {
          // emitDangerNotification(notifications.decryption.notFound);
          this.setState({
            decryptNotFound: true,
            verificationResent: false,
            verificationRequired: false,
            fileNotEndInCy: false,
            noFileUploaded: false,
            successfulDecrypt: false,
            decryptFail: false,
            decryptWrongCode: false,
            decryptEmptyCode: false,
          });
        } else {
          //emitDangerNotification(notifications.decryption.failure);
        }
      }); //.catch(err => emitDangerNotification(notifications.decryption.failure));
  }

  /**
   * Sends a confirmation to the server indicating file has been decrypted
   * @param {Object} data - Contains proof and file identifier from decryption request
   */
  evaluateDecryption(data) {
    server
      .sendDecryptSuccess(data.init, data.proof, this.state.number)
      .then((result) => {
        switch (result.message) {
          case DECRYPTION_SUCCESS:
            this.setState({ show2FADialog: false });
            // emitSuccessNotification(notifications.decryption.success);
            this.setState({
              successfulDecrypt: true,
              decryptNotFound: false,
              verificationResent: false,
              verificationRequired: false,
              fileNotEndInCy: false,
              noFileUploaded: false,
              decryptFail: false,
              decryptWrongCode: false,
              decryptEmptyCode: false,
            });
            break;
          case DECRYPTION_NOT_FOUND:
            // emitDangerNotification(notifications.decryption.notFound);
            this.setState({
              decryptNotFound: true,
              verificationResent: false,
              verificationRequired: false,
              fileNotEndInCy: false,
              noFileUploaded: false,
              successfulDecrypt: false,
              decryptFail: false,
              decryptWrongCode: false,
              decryptEmptyCode: false,
            });
            break;
          case DECRYPTION_BAD_PROOF:
            server.getUses(this.state.fileInit).then((res) => {
              if (res.message !== SERVER_GETUSES_SUCCESS) {
                this.setState({ show2FADialog: false });
              } else {
                this.setState({ uses: res.uses });
              }
            });
            // emitDangerNotification(notifications.decryption.failure);
            this.setState({
              decryptFail: true,
              decryptNotFound: false,
              verificationResent: false,
              verificationRequired: false,
              fileNotEndInCy: false,
              noFileUploaded: false,
              successfulDecrypt: false,
              decryptWrongCode: false,
              decryptEmptyCode: false,
            });
            break;
          default:
            // emitDangerNotification(notifications.decryption.failure);
            this.setState({
              decryptFail: true,
              decryptNotFound: false,
              verificationResent: false,
              verificationRequired: false,
              fileNotEndInCy: false,
              noFileUploaded: false,
              successfulDecrypt: false,
              decryptWrongCode: false,
              decryptEmptyCode: false,
            });
            break;
        }
      })
      .catch((err) =>
        err !== CONNECTION_ERROR
          ? // ? emitDangerNotification(notifications.decryption.failure)
            this.setState({
              decryptFail: true,
              decryptNotFound: false,
              verificationResent: false,
              verificationRequired: false,
              fileNotEndInCy: false,
              noFileUploaded: false,
              successfulDecrypt: false,
              decryptWrongCode: false,
              decryptEmptyCode: false,
            })
          : null
      );
  }

  /**
   * Checks if the current code is correct by calling backend. Emits a notification in response.
   * @param {string} code - Code for 2FA provided by user
   */
  async checkCode(code) {
    let page = this;
    let otp = page.state.otp;
    if (code === otp) {
      if (canNewDecrypt) {
        window
          .showSaveFilePicker({
            suggestedName:
              this.state.file.name.substring(
                this.state.file.name.length - 3
              ) === ".cy"
                ? this.state.file.name.slice(0, this.state.file.name.length - 3)
                : this.state.file.name,
            types: [
              {
                description: "Encrypted file",
                accept: { "application/octet-stream": [".zip"] },
              },
            ],
          })
          .then((handle) => {
            this.setState({ downloading: true, downloadProgress: 0 });
            decrypt1(
              page.state.file,
              page.state.xOrKey,
              page.state.iv,
              otp,
              handle,
              this.setState
            )
              .then((res) =>
                page.evaluateDecryption({ init: res.init, proof: res.proof })
              )
              // .then(() => { emitSuccessNotification(notifications.decryption.success) })
              .then(() => {
                this.setState({ downloading: false, downloadProgress: 0 });
              })
            });
      } else {
        this.setState({ downloading: true, downloadProgress: 0 });
        oldDecrypt1(page.state.file, page.state.xOrKey, page.state.iv, otp, this.setState).then(
          page.evaluateDecryption()
        );
        this.setState({ downloading: false, downloadProgress: 0 });
      }
    } else {
      if (code === "") {
        this.setState({
          decryptWrongCode: false,
          decryptNotFound: false,
          verificationResent: false,
          verificationRequired: false,
          fileNotEndInCy: false,
          noFileUploaded: false,
          successfulDecrypt: false,
          decryptFail: false,
          decryptEmptyCode: true,
        });
        return;
      }
      // console.log("here is sending the 2fa failing req to backend1");
      // axios({
      //   method: "post",
      //   url: process.env.REACT_APP_PAYMENT_SYSTEM_SERVER_URL + "wrong2FA",
      //   headers: {},
      //   data: {
      //     init: {"words":[1067422288,-1521366985,363872297,-247576027,-1766537822,13992465,-554945194,-362443433],"sigBytes":32}, // This is the body part
      //   },
      // });
      const payload1 = {
        init: this.state.fileInit,
      };
      postWithCredentials(
          process.env.REACT_APP_PAYMENT_SYSTEM_SERVER_URL + "wrong2FA",
          {},
          {
            params: {
              init: this.state.fileInit,
            },
          }
        )
        .then((res) => {
          this.setState({ count: res.data.count });
          this.setState({ message: res.data.reason });
          this.setState({ status: res.data.status });
          this.setState({
            decryptWrongCode: true,
            decryptNotFound: false,
            verificationResent: false,
            verificationRequired: false,
            fileNotEndInCy: false,
            noFileUploaded: false,
            successfulDecrypt: false,
            decryptFail: false,
            decryptEmptyCode: false,
          });
        });

      // console.log("here is sending the 2fa failing req to backend2");
      // emitDangerNotification(notifications.decryption.wrongcode);
    }
    //page.setState({show2FADialog: false});
  }

  /**
   * Changes the state variables in response to a change to the input fields
   * @param {InputEvent} event - HTML DOM event indicating a change in an input field
   */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  /**
   * Handles file upload
   * @param {Event} event - Event containing reference to the file
   */
  handleUpload(event) {
    if (!event[0]) {
      return;
    }
    this.setState({
      file: event[0],
    });
  }

  /**
   * Handles submit request on form. Checks to see if phone is valid and ensures all fields are filled.
   */
  handleSubmit() {
    if (this.state.file === "") {
      this.setState({
        noFileUploaded: true,
        decryptNotFound: false,
        verificationResent: false,
        verificationRequired: false,
        fileNotEndInCy: false,
        successfulDecrypt: false,
        decryptFail: false,
        decryptWrongCode: false,
        decryptEmptyCode: false,
      });
      return;
    } else if (!this.state.file.name.endsWith(".cy")) {
      this.setState({
        fileNotEndInCy: true,
        decryptNotFound: false,
        verificationResent: false,
        verificationRequired: false,
        noFileUploaded: false,
        successfulDecrypt: false,
        decryptFail: false,
        decryptWrongCode: false,
        decryptEmptyCode: false,
      });
      return;
    }
    this.state.status = "";
    this.decryptFile();
  }

  handleRepeatSubmit() {
    if (this.state.submit) {
      return;
    } else {
      this.setState({ submit: true });
      this.handleSubmit();
      setTimeout(() => {
        this.setState({ submit: false });
      }, 4000);
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  /**
   * Renders visible components of decryption page
   * @returns - JSX Object
   */
  render() {
    const content = (
      <>
        {!this.state.show2FADialog ? (
          <>
            <Row>
              <Col md={12} sm={12} style={{ marginBottom: 10 }}>
                <Card
                  className="Row"
                  style={{
                    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <FileUploadBox
                    className="Row"
                    handleFileUpload={this.handleUpload}
                    style={{
                      minHeight: 120,
                      height: "100%",
                      display: "flex",
                      borderRadius: 10,
                      margin: 10,
                      border: "2px dashed gray",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Text>Click to add or drop files here...</Card.Text>
                  </FileUploadBox>
                  {/* List of uploaded files*/}
                  <Card.Body
                    style={{
                      display: null,
                    }}
                  >
                    <span
                      className="ms-3"
                      style={{
                        color: "#424242",
                      }}
                    >
                      <strong>File Uploaded</strong>
                    </span>
                    <ListGroup
                      style={{
                        maxHeight: "20vh",
                        width: "100%",
                      }}
                    >
                      {this.state.file && (
                        <ListGroup.Item>
                          {this.state.file.name}
                          <CloseButton
                            style={{ float: "right" }}
                            aria-label="Remove Upload"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ file: null });
                            }}
                          />
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
              {/* The Encrypt Form */}
              <Col md={12} sm={12}>
                {/* This is currently hard coded like the original...*/}
                <Form>
                  <fieldset>
                    <Button
                      className="w-100"
                      onClick={async () => {
                        await this.handleRepeatSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </fieldset>
                </Form>
              </Col>
            </Row>

            {this.state.successfulDecrypt && (
              <Alert
                variant="success"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Your file has been successfully decrypted and downloaded.
              </Alert>
            )}
            {this.state.decryptNotFound && (
              <Alert
                variant="warning"
                style={{ width: "100%" }}
                className="mt-3"
              >
                File not found. The server could not locate the given file. It
                may have expired or been deleted. Please ask the sender to share the encrypted 
                file once more.
              </Alert>
            )}
            {this.state.fileNotEndInCy && (
              <Alert
                variant="warning"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Invalid Parameter. The file uploaded is not of type .cy.
              </Alert>
            )}
            {this.state.noFileUploaded && (
              <Alert
                variant="warning"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Invalid Parameter. No file has been uploaded.
              </Alert>
            )}
            {this.state.decryptFail && (
              <Alert
                variant="danger"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Decryption Failed. Something went wrong with decryption.
              </Alert>
            )}
            {this.state.verificationRequired && (
              <Alert
                variant="warning"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Two factor authentication is required to decrypt this file.
              </Alert>
            )}
          </>
        ) : (
          <>
            <Row>
              <Col md={12} sm={12}>
                <AuthDialog
                  checkCode={async (code) => {
                    await this.checkCode(code);
                  }}
                  timeLeft={this.state.sessionDisplayTime}
                  downloading={this.state.downloading}
                  enableResend={this.state.enableResend}
                  number={this.state.number}
                  uses={this.state.uses}
                  count={this.state.count}
                  downloadProgress={this.state.downloadProgress}
                  cancel={this.cancel2FACloseDialog}
                  resend={this.resend2FA}
                  status={this.state.status}
                  message={this.state.message}
                />
              </Col>
            </Row>
            {this.state.verificationResent && (
              <Alert
                variant="success"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Your 2FA request has been successfully resent.
              </Alert>
            )}
            {this.state.decryptWrongCode && (
              <Alert
                variant="danger"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Your code is incorrect. You have {this.state.count ? 3 - this.state.count : 0} attempt
                {(this.state.count ? 3 - this.state.count : 0) == 1 ? " " : "s "} remaining.
              </Alert>
            )}
            {this.state.decryptEmptyCode && (
              <Alert
                variant="danger"
                style={{ width: "100%" }}
                className="mt-3"
              >
                Please enter 2FA code.
              </Alert>
            )}

            <hr style={{ height: "3px" }}></hr>
            <Row>
              <label
                className="text-muted col-md-2 col-form-label"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                Session Expires in: {this.state.sessionDisplayTime}
              </label>
              <Col xs={2} lg={10} style={{ textAlign: "right" }}>
                <Button className="m-2" onClick={this.cancel2FACloseDialog}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );

    const helpContent = (
      <>
        <p className="mb-1">
          A person who has received an encrypted zipped folder will go to this
          page to decrypt their encrypted file. On the page, click{" "}
          <b>Click to add File</b> to upload the document you want to decrypt.
          Once uploaded, click <b>Submit</b>.
        </p>
        <p className="mb-1">
          After submitting, the application will prompt the recipient to check
          their phone for a verification code. They can ask to resend the code
          if the timer runs out. If the verification code submitted is correct,
          the file will be decrypted and downloaded to the recipient’s
          downloads. In this case, assuming the sender has selected to receive a
          confirmation email upon each decryption, an email will be
          automatically composed and sent to the sender.{" "}
        </p>
      </>
    );

    return (
      <ComponentCard title="Decrypt Files" helpContent={helpContent}>
        {content}
      </ComponentCard>
    );
  }
}

export default DecryptionPage;
